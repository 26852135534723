import ChatRoot from "../chat/ChatRoot";
import * as React from "react";
import gameApi from "../api/gameApi";
import {useParams} from "react-router-dom";
import {useState} from "react";
import WaitingRoomDialog from "../components/WaitingRoomDialog";
import TagImagesDialog from "../components/TagImagesDialog";
import TagOptionsDialog from "../components/TagOptionsDialog";
import useGuest from "../hooks/useGuest";
import ResultsDialog from "../components/ResultsDialog";
import {Alert, Snackbar} from "@mui/material";
import {useTranslation} from "react-i18next";
import CountdownOverlay from "./CountdownOverlay";
import {useRecoilState} from "recoil";
import {timerFinishedAtState} from "../recoil_state";

export const DIFFICULTIES_OPTIONS = { //mapped to java's object!
    STANDARD: "STANDARD",
    CHALLENGING: "CHALLENGING"
}

export default function Game({data, setData, setCurrentDifficulty, setPlayerNames, playerNames, gameName, puzzleName}) {
    const {gameId, groupId} = useParams();
    const [matchReady, setMatchReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showWaitPartner, setShowWaitPartner] = useState(false);
    const [tagImages, setTagImages] = useState(null);
    const [tagOptions, setTagOptions] = useState(null);
    const [showTagOptions, setShowTagOptions] = useState(false);
    const [orderTagOptions, setOrderTagOptions] = useState(null);
    const [showOrderTagOptions, setShowOrderTagOptions] = useState(false);
    const {isGuest} = useGuest();
    const [waiting, setWaiting] = useState(null);
    const [results, setResults] = useState(null);
    const [resultOpen, setResultOpen] = useState(false);
    const [showResultsDialog, setShowResultsDialog] = useState(false);
    const [allowToUnlockNextPuzzle, setAllowToUnlockNextPuzzle] = useState(false);
    const [, setTimerFinishedAt] = useRecoilState(timerFinishedAtState);

    const { t } = useTranslation();
    const { t:tPuzzle } = useTranslation('puzzle');
    const { t: transGame } = useTranslation('game');


    const onSend = (innerHtml, textContent, innerText, nodes) => {
        const req = {message: textContent}
        gameApi.sendMessage(gameId, groupId, req, isGuest)
            .then(response => {
                setShowWaitPartner(response?.status === 202)
                setData(response?.data);
            })
    }

    const onTagImages = (message) => {
        setTagImages(message);
    }

    const onTagOptions = (message) => {
        setShowTagOptions(true);
        if (message?.action !== null && message?.action?.options != null) {
            setTagOptions({message, values: message?.action?.options});
            return;
        }
        setTagOptions({message, values: []});
    }

    const onOrderTagOptions = (message) => {
        setShowOrderTagOptions(true);
        if (message?.action !== null && message?.action?.options != null) {
            setOrderTagOptions({message, values: message?.action?.options});
            return;
        }
        setOrderTagOptions({message, values: []});
    }

    const onTagOptionsSubmit = (answersArray) => {
        // we will order alphabetically before sending
        answersArray.sort((a, b) => a.localeCompare(b))
        const finalAnswer = answersArray.join('-');
        const req = {message: finalAnswer, data}
        gameApi.sendMessage(gameId, groupId, req, isGuest)
            .then(response => {
                setShowWaitPartner(response?.status === 202)
                setData(response?.data);
            })
    }

    const onOrderTagOptionsSubmit = (answersArray) => {
        const finalAnswer = answersArray.join('-');
        const req = {message: finalAnswer, data}
        gameApi.sendMessage(gameId, groupId, req, isGuest)
            .then(response => {
                setShowWaitPartner(response?.status === 202)
                setData(response?.data);
            })
    }

    const onTagImagesSubmit = (dataMap) => {
        if (dataMap.size === 0) {
            return;
        }
        // index based answers, for example shrek-dog-nanny.
        // then let's format the map this way
        let finalAnswer = "";
        dataMap.forEach((value) => {
            const answ = value.sort((a, b) => a.localeCompare(b))
            if (finalAnswer === "") {
                finalAnswer = finalAnswer.concat(answ);
            } else {
                finalAnswer = finalAnswer.concat("-" + answ);
            }
        })

        const req = {message: finalAnswer, data}
        gameApi.sendMessage(gameId, groupId, req, isGuest)
            .then(response => {
                setShowWaitPartner(response?.status === 202)
                setData(response?.data);
            })
    }

    const getTagImagesOptions = () => {
        if (tagImages == null || tagImages.action === null) {
            return [];
        }
        return tagImages.action?.options;
    }

    const onTryToProceed = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        gameApi.getPlayerWaiting(gameId, groupId, isGuest)
            .then(d => {
                if (d?.amIwaiting) {
                    setWaiting(transGame('playerWaitingForPartner', { partnerName: playerNames?.opponentName}));
                } else {
                    setShowWaitPartner(false)
                    setWaiting(null);
                    setData(d?.game);
                }
                if (d?.timerFinished) {
                    // this might be null for guest player due to race condition
                    setTimerFinishedAt(d.timerFinished)
                }

                if (d?.playerNames) {
                    setPlayerNames(d.playerNames);
                }
                if (d?.results) {
                    setResults(d?.results)
                }
                if (d?.audio) {
                    // sound was here
                }
            }).finally(() => setLoading(false));
    }

    const tryOpenResultDialog = () => {
        gameApi.isAnotherPlayerFinished(gameId, groupId, isGuest)
            .then(d => {
                if (d?.results) {
                    setResults(d.results)
                }
                if (d?.resultVisible) {
                    setShowResultsDialog(true)
                    setResultOpen(false);
                } else {
                    setResultOpen(true);
                }
                if (!isGuest && d?.guestResultOpened) {
                    setAllowToUnlockNextPuzzle(true)
                }
            })
    }

    const onResultsClick = () => {
        tryOpenResultDialog();
    }

    return (
        <>
            <CountdownOverlay  />
            <WaitingRoomDialog
                setMatchReady={setMatchReady}
                matchReady={matchReady}
                setGameWithDifficulty={setData}
                setCurrentDifficulty={setCurrentDifficulty}
                setPlayerNames={setPlayerNames}
                playerNames={playerNames}
            />
            <Snackbar
                open={resultOpen}
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                onClose={() => setResultOpen(false)}
            >
                <Alert onClose={() => setResultOpen(false)} severity="error" sx={{width: '100%'}}>
                    {t('waitForGo')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={waiting !== null}
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                onClose={() => setResultOpen(null)}
            >
                <Alert onClose={() => setWaiting(null)} severity="warning" sx={{width: '100%'}}>
                    {waiting}
                </Alert>
            </Snackbar>
            <TagImagesDialog
                tagImages={tagImages}
                options={getTagImagesOptions()}
                closeDialog={() => setTagImages(null)}
                onSubmit={onTagImagesSubmit}
            />
            {tagOptions && (
                <TagOptionsDialog
                    tagOptions={tagOptions.message}
                    availableOptions={tagOptions.values}
                    open={showTagOptions}
                    closeDialog={() => setShowTagOptions(false)}
                    onSubmit={onTagOptionsSubmit}
                />
            )}
            {orderTagOptions && (
                <TagOptionsDialog
                    tagOptions={orderTagOptions?.message}
                    availableOptions={orderTagOptions?.values}
                    open={showOrderTagOptions}
                    closeDialog={() => setShowOrderTagOptions(false)}
                    onSubmit={onOrderTagOptionsSubmit}
                    isOrdered
                />
            )}
            <ResultsDialog
                results={results}
                open={showResultsDialog}
                onClose={() => setShowResultsDialog(false)}
                allowToUnlockNextPuzzle={allowToUnlockNextPuzzle}
                playerNames={playerNames}
                gameName={tPuzzle(gameName)}
                puzzleName={tPuzzle(puzzleName)}
                scoreCapForDifficultyChange={data?.puzzle?.scoreCapForDifficultyChange}

            />
            {data && matchReady && (
                <ChatRoot
                    data={data}
                    onSend={onSend}
                    onTagImages={onTagImages}
                    onTagOptions={onTagOptions}
                    onOrderTagOptions={onOrderTagOptions}
                    showWaitPartner={showWaitPartner}
                    onTryToProceed={onTryToProceed}
                    onResultsClick={results == null ? null : onResultsClick}
                    playerNames={playerNames}
                />
            )}
        </>
    )
}
