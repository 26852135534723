import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Stack,
    TextField
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import gameApi from "../api/gameApi";
import { useParams, useSearchParams } from "react-router-dom";
import { CopyField } from "@eisberg-labs/mui-copy-field";
import {DIFFICULTIES_OPTIONS} from "../game/Game";
import { getInviteLink } from "../utils/shared";
import useGuest from "../hooks/useGuest";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import {useRecoilState} from "recoil";
import {timerConfigState, timerStartedAtState} from "../recoil_state";

export default function WaitingRoomDialog(
    {
        setMatchReady,
        matchReady,
        setGameWithDifficulty,
        setCurrentDifficulty,
        setPlayerNames,
        playerNames
    }
) {
    const { gameId, groupId } = useParams();
    const [alert, setAlert] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [apiRequested, setApiRequested] = useState(false);
    const [searchParams,] = useSearchParams();
    const nextDifficulty = searchParams.get("difficulty");
    const { isGuest } = useGuest();
    const [difficulty, ] = useState(nextDifficulty === null ? DIFFICULTIES_OPTIONS.STANDARD : nextDifficulty);
    const [guestName, setGuestName] = useState(playerNames === null ? '' : playerNames?.myName);
    const { t } = useTranslation();
    const [, setTimerConfig] = useRecoilState(timerConfigState);
    const [, setTimerStartedAt] = useRecoilState(timerStartedAtState);

    const onNameChange = (event) => {
        setGuestName(event.target.value);
    }

    const resolveText = () => {
        if (isGuest) {
            return t('askPartnerToStart');
        }
        return t('guestPlayerNotReady');
    }

    const onClickPlay = () => {
        setApiRequested(false)
        setWaiting(true);
        setLoading(true)
        gameApi.setPlayerReady(gameId, groupId, isGuest, difficulty, guestName)
            .then(d => {
                setMatchReady(d?.opponentReady)
                if (!d?.opponentReady) {
                    setAlert(resolveText())
                }
                if (d?.gameData !== null) {
                    setGameWithDifficulty(d.gameData);
                }
                if (d?.timerStarted) {
                    // set timer start as it is returned by API. this also includes countdown delay
                    setTimerStartedAt(d.timerStarted);
                    setTimerConfig(d?.gameData.puzzle.timer);
                }
                if (d?.difficulty) {
                    setCurrentDifficulty(d?.difficulty)
                }

                if (d?.playerNames) {
                    setPlayerNames(d?.playerNames)
                }
            }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (difficulty) {
            setApiRequested(true)
        }
    }, [difficulty]);

    useEffect(() => {
        if (apiRequested) {
            onClickPlay()
        }

    }, [apiRequested]); // eslint-disable-line

    const getWaitingText = () => (
        <>
            {t('minimumPlayersText')}
        </>
    )

    const renderPlayer = () => (
        <>
            <DialogTitle>
                <Stack px={4}>
                    <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} align="center">
                        {t('shareLinkDialogTitle')}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ marginTop: '8px' }}>
                <DialogContentText>
                    <Stack spacing={2} gap={3}>
                        {!!alert && (
                            <Alert severity="warning">
                                {alert}
                            </Alert>
                        )}
                        {waiting ? '' : getWaitingText()}
                        <CopyField
                            sx={{ marginTop: 1 }}
                            label={t('copyInviteLinkLabel')}
                            value={getInviteLink(gameId, groupId)}
                        />
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ paddingX: '24px', paddingBottom: '40px' }}>
                {<Button fullWidth size="large" variant="contained" color="success" onClick={onClickPlay}>
                    {matchReady ? t('playButtonText') : t('getStartText')}
                </Button>}

            </DialogActions>
        </>
    )

    const renderGuest = () => (
        <>
            <DialogTitle id="responsive-dialog-title">
                <Stack px={4}>
                    <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} align="center">
                        {t('startGameDialogTitle')}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Stack spacing={2}>
                        {alert && <Alert severity="warning">{alert}</Alert>}
                        {waiting ? '' : getWaitingText()}
                    </Stack>
                    {!playerNames?.myName && <Box mt={2}>
                        <FormControl fullWidth>
                            <TextField onChange={onNameChange} label={t('yourNameLabel')} value={guestName} variant="outlined"
                                       required />
                        </FormControl>
                    </Box>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {<Button fullWidth size="large" variant="contained" color="success" onClick={onClickPlay}>
                    {matchReady ? t('playButtonText') : t('getStartText')}
                </Button>}
            </DialogActions>
        </>
    )

    return (
        <>
            {!loading && (
                <Dialog
                    open={!matchReady}
                    aria-labelledby="responsive-dialog-title"
                    style={{ backdropFilter: "blur(5px)" }}
                >
                    {isGuest ? renderGuest() : renderPlayer()}
                </Dialog>
            )}
        </>
    )
}
