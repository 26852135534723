import {InputLabel, MenuItem, Select, Stack} from "@mui/material";
import * as React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import useGuest from "../hooks/useGuest";
import {DIFFICULTIES_OPTIONS} from "../game/Game";
import DifficultyPromptModal from "./DifficultyPromptModal";
import {useState} from "react";
import {useTranslation} from "react-i18next";

export default function DifficultyDropdown({ currentDifficulty }) {
    const { isGuest } = useGuest();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [newDifficulty, setNewDifficulty] = useState(null);
    const { t } = useTranslation();
    const { t:tPuzzle } = useTranslation('puzzle');


    const handleDifficultyChange = (e) => {
        setNewDifficulty(e.target.value);
    }
    const onConfirm = () => {
        // noinspection JSCheckFunctionSignatures
        setSearchParams({difficulty: newDifficulty});
        navigate(0);
    }

    const getDifficulty = () => {
        const query = searchParams.get("difficulty");
        if (query !== null) {
            return query;
        }
        return currentDifficulty;
    }

    return (
        <>
            <DifficultyPromptModal onClose={() => setNewDifficulty(null)} onConfirm={onConfirm} newDifficulty={newDifficulty} />
            <Stack sx={{ width: '100%' }} gap={1}>
                <InputLabel id="difficulty-label">{t('difficulty')}</InputLabel>
                <Select
                    fullWidth
                    id="difficulty"
                    value={getDifficulty()}
                    label={t('difficulty')}
                    onChange={handleDifficultyChange}
                    disabled={isGuest}
                >
                    {Object.values(DIFFICULTIES_OPTIONS).map(k => <MenuItem key={k} value={k}>{tPuzzle(`translate.game.${k}`)}</MenuItem>)}
                </Select>
            </Stack>
        </>
    )
}
