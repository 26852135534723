// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                resultsNavigation: {
                    faqUrl: "https://couchclues.com/#faq",
                    shopUrl: "https://couchclues.com/chapters/a-toxic-case/full/"
                },
                game: {
                    language: "Language",
                    languageEn: "English",
                    languageNl: "Not available",
                    continue: "CONTINUE!",
                    playNow: "Play Now",
                    seeResults: "See Results",
                    selectLanguage: "Please select the language",
                    go: "GO!",
                    results: "Results",
                    completed: "Completed:",
                    noPuzzles: "No puzzles available. Please visit the website to buy a game",
                    typeToAnswer: "Type answer here",
                    clickToTag: "Click to tag images",
                    clickToTagAnswer: "Click to tag answers",
                    clickToTagAnswerInOrder: "Tag answers in the right order",
                    backToChat: "Back to chat",
                    chatWith: "Chatting with",
                    difficulty: "Difficulty",
                    copyLink: "Copy this link and share it with your friend:",
                    copyLinkLabel: "Copy invite link",
                    inviteFriend: "Invite a friend",
                    about: "About",
                    faq: "FAQ",
                    shop: "Shop",
                    waitForGo: "Please wait for the another player to press GO!",
                    shareAlinkWithPlayer: "Share a link to invite another player",
                    askPartnerToStart: "Ask your partner to start the game. We are waiting... ",
                    guestPlayerNotReady: "Guest player is not ready yet. We are waiting...",
                    minimumPlayersText: "You need at least two players to play the game. {!isGuest ? ' Send the link to your friend and invite him to play ' : ''}",
                    copyInviteLinkLabel: "Copy invite link",
                    playerWaitingForPartner: "Wait for {{partnerName}} to enter the right solution. Maybe you can help?",
                    difficultyLabel: "Difficulty",
                    startButtonText: "Start the game",
                    playButtonText: "Play!",
                    shareLinkDialogTitle: "To start the game, share the link below with another player",
                    startGameDialogTitle: "Let's solve it together!",
                    yourNameLabel: "Your name",
                    getStartText: "Start the game",
                    errorGoHome: "Go home",
                    error: "Error",
                    notExists: "The game you’re looking for does not exist",
                    buyAnother: "You need to buy another chapter in order to play this game",
                    youDontHave: "You do not have this chapter",
                    getPuzzle: "Buy chapter",
                },
                puzzle: {
                    'translate.game.STANDARD': 'Standard',
                    'translate.game.CHALLENGING': 'Challenging',
                    'translate.game.paradise': 'A Case in Paradise',
                    'translate.game.hint.explanation.label': "Explanation",
                    'translate.game.hint.hints.label': "Hints",
                    'translate.game.hint.hint1.label': "Hint 1",
                    'translate.game.hint.hint2.label': "Hint 2",
                    'translate.game.hint.solution.label': "Solution",
                    'translate.game.puzzle.timer.watch_video': "Watch the following video:",

                    'translate.game.hint.explanation_minus_points.label': "Explanation",
                    'translate.game.hint.hint1_minus_points.label': "Hint 1",
                    'translate.game.hint.hint2_minus_points.label': "Hint 2",

                    'translate.game.paradise.puzzle_name.intro1': "Intro 1",
                    'translate.game.paradise.puzzle_name.intro2': "Intro 2",
                    'translate.game.paradise.puzzle_name.the_knight': "The Knight",
                    'translate.game.paradise.puzzle_name.the_blackout': "The Blackout",
                    'translate.game.paradise.puzzle_name.the_witnesses': "The Witnesses",
                    'translate.game.paradise.puzzle_name.the_key': "The Key",
                    'translate.game.paradise.puzzle_name.the_interrogation': "The Interrogation",
                    'translate.game.paradise.puzzle_name.the_card_game': "The Card Game",
                    'translate.game.paradise.puzzle_name.the_missing_items': "The Missing Items",
                    'translate.game.paradise.puzzle_name.the_traces': "The Traces",
                    'translate.game.paradise.puzzle_name.the_security_tapes': "The Security Tapes",
                    'translate.game.paradise.puzzle_name.whodunnit': "Whodunnit",

                    'translate.game.messages.answers.waiting': "OK, I know what to do. Let {{partnerName}} know that it is time to Go!",
                    'translate.game.messages.answers.incorrectWhenOpponentCorrect': "Wait! {{partnerName}} sent me something as well...",
                    'translate.game.messages.answers.bothIncorrect': "I tried your solutions but they didnt work.",
                    'translate.game.messages.answers.opponentCorrect': "Your solution didn't work, but the one {{partnerName}} sent me did!",
                    'translate.game.messages.answers.diffAnswersImRightButWaiting': "What you said seems correct, but the answer from {{partnerName}} is incorrect. Help {{partnerName}} out and tell me to go when they're ready.",

                    'translate.game.score.65': "Not bad! You scored better than 50% of players",
                    'translate.game.score.75': "Nice! You had a better score than 75% of all other players",
                    'translate.game.score.80': "Good score! You are in the top 20% of all players",
                    'translate.game.score.85': "Very good score! You are in the top 15% for this challenge",
                    'translate.game.score.90': "Great score! You are in the top 10% for this challenge.",
                    'translate.game.score.95': "Almost perfect! You are in the top 5% for this puzzle!",
                    'translate.game.score.100': "Congratulations! Less than 1% of players get a 100 for this puzzle.",
                    'translate.game.score.140': "Not bad! Better than 50% of players on 'challenging' level",
                    'translate.game.score.150': "Nice! You scored better than 55% of players on 'challenging' level",
                    'translate.game.score.160': "Well done! You are in the top 25% of players (that play 'challenging' difficulty)",
                    'translate.game.score.170': "Good score: You two in the top 20% (for 'challenging' difficulty)",
                    'translate.game.score.180': "Very good score! You are in the top 15% for this challenge (on 'challenging' level)",
                    'translate.game.score.190': "Great score! You are in the top 10% for this challenge (on 'challenging' level)",
                    'translate.game.score.195': "Almost perfect! You two are in the top 5% for this puzzle!",
                    'translate.game.score.200': "Amazing! Less than 1% of teams that play on challenging difficulty gets a 100 for this puzzle.",
                    'translate.game.score.241': "Impressive! Better than 50% of players on 'crazy' difficulty",
                    'translate.game.score.250': "Nice! You scored better then 55% of players on 'crazy' difficulty level",
                    'translate.game.score.260': "Well done! You are in the top 25% for this challenge on 'crazy' difficulty level",
                    'translate.game.score.270': "Good score: You two in the top 20% on 'crazy' difficulty level",
                    'translate.game.score.280': "Very good score! You are in the top 15% for this challenge on 'crazy' difficulty level",
                    'translate.game.score.290': "Great score! You are in the top 10% for this challenge on 'crazy' difficulty level",
                    'translate.game.score.295': "You are the crazy geniuses within the crazy geniuses group! You are in the top 5%",
                    'translate.game.score.300': "Wow! OK, there are 2 options. You are crazy geniuses, or you are cheating. But we don't want to tell crazy geniuses that they are cheating, so well done!",

                    'translate.1_intro_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro1thumb.webp",
                    'translate.1_intro_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro1thumb.webp",

                    'translate.2_intro_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro2thumb.webp",
                    'translate.2_intro_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop15.webp",
                    'translate.2_intro_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop17.webp",
                    'translate.2_intro_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop21.webp",
                    'translate.2_intro_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop29.webp",
                    'translate.2_intro_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro2thumb.webp",
                    'translate.2_intro_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop15.webp",
                    'translate.2_intro_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop17.webp",
                    'translate.2_intro_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop21.webp",
                    'translate.2_intro_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop29.webp",

                    'translate.the_knight_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/1thumb.webp",
                    'translate.the_knight_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",
                    'translate.the_knight_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",
                    'translate.the_knight_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p1.webp",
                    'translate.the_knight_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",

                    'translate.the_knight_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",
                    'translate.the_knight_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",
                    'translate.the_knight_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p1.webp",
                    'translate.the_knight_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",

                    'translate.the_blackout_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/2thumb.webp",
                    'translate.the_blackout_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2p1.webp",
                    'translate.the_blackout_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2p2.webp",
                    'translate.the_blackout_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/2thumb.webp",
                    'translate.the_blackout_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2p1.webp",
                    'translate.the_blackout_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2p2.webp",

                    'translate.the_witnesses_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/3thumb.webp",
                    'translate.the_witnesses_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3b.webp",
                    'translate.the_witnesses_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3p1.webp",
                    'translate.the_witnesses_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3b.webp",
                    'translate.the_witnesses_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3p2.webp",
                    'translate.the_witnesses_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/3thumb.webp",
                    'translate.the_witnesses_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3b.webp",
                    'translate.the_witnesses_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3p1.webp",
                    'translate.the_witnesses_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3b.webp",
                    'translate.the_witnesses_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3p2.webp",

                    'translate.the_key_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/4thumb.webp",
                    'translate.the_key_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP4b.webp",
                    'translate.the_key_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP4b.webp",
                    'translate.the_key_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/4thumb.webp",
                    'translate.the_key_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP4b.webp",
                    'translate.the_key_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP4b.webp",

                    'translate.the_interrogation_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/5thumb.webp",
                    'translate.the_interrogation_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5b.webp",
                    'translate.the_interrogation_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5b.webp",
                    'translate.the_interrogation_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5p1.webp",
                    'translate.the_interrogation_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5p2.webp",
                    'translate.the_interrogation_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/5thumb.webp",
                    'translate.the_interrogation_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5b.webp",
                    'translate.the_interrogation_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5b.webp",
                    'translate.the_interrogation_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5p1.webp",
                    'translate.the_interrogation_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5p2.webp",


                    'translate.the_card_game_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/6thumb.webp",
                    'translate.the_card_game_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6b.webp",
                    'translate.the_card_game_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1a.webp",
                    'translate.the_card_game_standard.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1b.webp",
                    'translate.the_card_game_standard.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1c.webp",
                    'translate.the_card_game_standard.player.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1d.webp",
                    'translate.the_card_game_standard.player.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1e.webp",
                    'translate.the_card_game_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6b.webp",
                    'translate.the_card_game_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2v.webp",
                    'translate.the_card_game_standard.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2w.webp",
                    'translate.the_card_game_standard.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2x.webp",
                    'translate.the_card_game_standard.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2y.webp",
                    'translate.the_card_game_standard.guest.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2z.webp",

                    'translate.the_card_game_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/6thumb.webp",
                    'translate.the_card_game_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6b.webp",
                    'translate.the_card_game_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1a.webp",
                    'translate.the_card_game_challenging.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1b.webp",
                    'translate.the_card_game_challenging.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1c.webp",
                    'translate.the_card_game_challenging.player.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1d.webp",
                    'translate.the_card_game_challenging.player.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1e.webp",
                    'translate.the_card_game_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6b.webp",
                    'translate.the_card_game_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2v.webp",
                    'translate.the_card_game_challenging.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2w.webp",
                    'translate.the_card_game_challenging.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2x.webp",
                    'translate.the_card_game_challenging.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2y.webp",
                    'translate.the_card_game_challenging.guest.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2z.webp",

                    'translate.the_missing_items_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/7thumb.webp",
                    'translate.the_missing_items_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7p1.webp",
                    'translate.the_missing_items_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7p2.webp",
                    'translate.the_missing_items_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/7thumb.webp",
                    'translate.the_missing_items_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7p1.webp",
                    'translate.the_missing_items_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7p2.webp",

                    'translate.the_traces_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/8thumb.webp",
                    'translate.the_traces_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP8p1.webp",
                    'translate.the_traces_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP8p2.webp",
                    'translate.the_traces_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/8thumb.webp",
                    'translate.the_traces_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP8p1.webp",
                    'translate.the_traces_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP8p2.webp",

                    'translate.the_security_tapes_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/9thumb.webp",
                    'translate.the_security_tapes_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9b.webp",
                    'translate.the_security_tapes_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9p1.webp",
                    'translate.the_security_tapes_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9b.webp",
                    'translate.the_security_tapes_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9p2.webp",
                    'translate.the_security_tapes_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/9thumb.webp",
                    'translate.the_security_tapes_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9b.webp",
                    'translate.the_security_tapes_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9p1.webp",
                    'translate.the_security_tapes_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9b.webp",
                    'translate.the_security_tapes_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9p2.webp",

                    'translate.whodunnit_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/10thumb.webp",
                    'translate.whodunnit_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10b.webp",
                    'translate.whodunnit_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10b.webp",
                    'translate.whodunnit_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10b.webp",
                    'translate.whodunnit_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10b.webp",

                    'translate.1_intro_simple.player.message.title': "Hi {{playerName}}! Welcome to Couch Clues! To play this game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11375804\" target=\"_blank\">Click this link to access Vimeo</a>, enter password 8712 and click 'Start Watching', select ENG subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CCACIPDevelopment' channel and find Playlist 'Couch Second Puzzle GT'. Then play the first video (Intro 1) and select ENG subtitles.",
                    'translate.1_intro_simple.player.message2.title': "You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper ready. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
                    'translate.1_intro_simple.player.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
                    'translate.1_intro_simple.player.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",
                    'translate.1_intro_simple.player.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click hint 1.",
                    'translate.1_intro_simple.player.hint.hint2': "The first digit is a 9. Tell this to your partner. The game is all about talking to each other!. Now check Hint 2.",
                    'translate.1_intro_simple.player.hint.hint3': "Did your partner tell you the last number? The second digit is a 3. You could now solve the puzzle, or access the solution to be sure. If you click the solution in the real game, you will not gain any points for that challenge.",
                    'translate.1_intro_simple.player.hint.solution': "The solution is 936. <br> Your first hint was that the first digit was a 9. The first hint for the other player was that the last digit was a 6. The second hint was the same for both of you: the second digit was a 3.",
                    'translate.1_intro_simple.guest.message.title': "Hi {{playerName}}! Welcome to Couch Clues! To play this game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11375804\" target=\"_blank\">Click this link to access Vimeo</a>, enter password 8712 and click 'Start Watching', select ENG subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CCACIPDevelopment' channel and find Playlist 'Couch Second Puzzle GT'. Then play the first video (Intro 1) and select ENG subtitles.",
                    'translate.1_intro_simple.guest.message2.title': "You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper ready. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
                    'translate.1_intro_simple.guest.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
                    'translate.1_intro_simple.guest.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",
                    'translate.1_intro_simple.guest.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click Hint 1.",
                    'translate.1_intro_simple.guest.hint.hint2': "The last digit is a 6. Be sure to tell this to your partner. This game is all about talking to each other!. Now check Hint 2",
                    'translate.1_intro_simple.guest.hint.hint3': "Did your partner tell you the first number? The second digit is a 3. You could now solve the puzzle, or access the solution to be sure. If you click the solution in the real game, you will not gain any points for that challenge.",
                    'translate.1_intro_simple.guest.hint.solution': "The solution is 936. <br> Your first hint was that the last digit was a 6. The first hint for the other player was that the first digit was a 9. The second hint was the same for both of you: the second digit was a 3.",

                    'translate.1_intro_easy.player.message.title': "Hi {{playerName}}! Welcome to Couch Clues! To play this game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11375804\" target=\"_blank\">Click this link to access Vimeo</a>, enter password 8712 and click 'Start Watching', select ENG subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CCACIPDevelopment' channel and find Playlist 'Couch Second Puzzle GT'. Then play the first video (Intro 1) and select ENG subtitles.",
                    'translate.1_intro_easy.player.message2.title': "You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper ready. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
                    'translate.1_intro_easy.player.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
                    'translate.1_intro_easy.player.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",
                    'translate.1_intro_easy.player.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click hint 1.",
                    'translate.1_intro_easy.player.hint.hint2': "The first digit is a 9. Tell this to your partner. The game is all about talking to each other!. Now check Hint 2.",
                    'translate.1_intro_easy.player.hint.hint3': "Did your partner tell you the last number? The second digit is a 3. You could now solve the puzzle, or access the solution to be sure. If you click the solution in the real game, you will not gain any points for that challenge.",
                    'translate.1_intro_easy.player.hint.solution': "The solution is 936. <br> Your first hint was that the first digit was a 9. The first hint for the other player was that the last digit was a 6. The second hint was the same for both of you: the second digit was a 3.",
                    'translate.1_intro_easy.guest.message.title': "Hi {{playerName}}!Welcome to Couch Clues! To play this game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11375804\" target=\"_blank\">Click this link to access Vimeo</a>, enter password 8712 and click 'Start Watching', select ENG subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CCACIPDevelopment' channel and find Playlist 'Couch Second Puzzle GT'. Then play the first video (Intro 1) and select ENG subtitles.",
                    'translate.1_intro_easy.guest.message2.title': "You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper ready. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
                    'translate.1_intro_easy.guest.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
                    'translate.1_intro_easy.guest.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",
                    'translate.1_intro_easy.guest.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click Hint 1.",
                    'translate.1_intro_easy.guest.hint.hint2': "The last digit is a 6. Be sure to tell this to your partner. This game is all about talking to each other!. Now check Hint 2",
                    'translate.1_intro_easy.guest.hint.hint3': "Did your partner tell you the first number? The second digit is a 3. You could now solve the puzzle, or access the solution to be sure. If you click the solution in the real game, you will not gain any points for that challenge.",
                    'translate.1_intro_easy.guest.hint.solution': "The solution is 936. <br> Your first hint was that the last digit was a 6. The first hint for the other player was that the first digit was a 9. The second hint was the same for both of you: the second digit was a 3.",

                    'translate.2_intro_simple.player.option1': "One",
                    'translate.2_intro_simple.player.option2': "Two",
                    'translate.2_intro_simple.player.option3': "Three",
                    'translate.2_intro_simple.player.option4': "Four",
                    'translate.2_intro_simple.player.option5': "Five",
                    'translate.2_intro_simple.player.option6': "Six",
                    'translate.2_intro_simple.player.option7': "Seven",
                    'translate.2_intro_simple.player.option8': "Eight",
                    'translate.2_intro_simple.player.option9': "Nine",
                    'translate.2_intro_simple.player.option10': "Zero",
                    'translate.2_intro_simple.guest.option1': "One",
                    'translate.2_intro_simple.guest.option2': "Two",
                    'translate.2_intro_simple.guest.option3': "Three",
                    'translate.2_intro_simple.guest.option4': "Four",
                    'translate.2_intro_simple.guest.option5': "Five",
                    'translate.2_intro_simple.guest.option6': "Six",
                    'translate.2_intro_simple.guest.option7': "Seven",
                    'translate.2_intro_simple.guest.option8': "Eight",
                    'translate.2_intro_simple.guest.option9': "Nine",
                    'translate.2_intro_simple.guest.option10': "Zero",

                    'translate.2_intro_simple.timer.title': "Intro 2 - A Case in Paradise",
                    'translate.2_intro_simple.timer.subtitle': "You will receive more information in:",
                    'translate.2_intro_simple.player.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
                    'translate.2_intro_simple.player.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
                    'translate.2_intro_simple.player.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_simple.player.message3.title': "That's right! You will now continue to the real game. Have fun together!",
                    'translate.2_intro_simple.player.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, change your diffuculty to Challenging in the menu on the top right. Everything else will stay the same.',
                    'translate.2_intro_simple.player.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
                    'translate.2_intro_simple.player.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_simple.player.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_simple.player.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",

                    'translate.2_intro_simple.guest.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
                    'translate.2_intro_simple.guest.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
                    'translate.2_intro_simple.guest.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_simple.guest.message3.title': "That's right! You will now continue to the real game. Have fun together!",
                    'translate.2_intro_simple.guest.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, ask the main player to change the diffuculty to Challenging in their menu. Everything else will stay the same.',
                    'translate.2_intro_simple.guest.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
                    'translate.2_intro_simple.guest.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_simple.guest.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_simple.guest.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",

                    'translate.2_intro_easy.player.option1': "One",
                    'translate.2_intro_easy.player.option2': "Two",
                    'translate.2_intro_easy.player.option3': "Three",
                    'translate.2_intro_easy.player.option4': "Four",
                    'translate.2_intro_easy.player.option5': "Five",
                    'translate.2_intro_easy.player.option6': "Six",
                    'translate.2_intro_easy.player.option7': "Seven",
                    'translate.2_intro_easy.player.option8': "Eight",
                    'translate.2_intro_easy.player.option9': "Nine",
                    'translate.2_intro_easy.player.option10': "Zero",
                    'translate.2_intro_easy.guest.option1': "One",
                    'translate.2_intro_easy.guest.option2': "Two",
                    'translate.2_intro_easy.guest.option3': "Three",
                    'translate.2_intro_easy.guest.option4': "Four",
                    'translate.2_intro_easy.guest.option5': "Five",
                    'translate.2_intro_easy.guest.option6': "Six",
                    'translate.2_intro_easy.guest.option7': "Seven",
                    'translate.2_intro_easy.guest.option8': "Eight",
                    'translate.2_intro_easy.guest.option9': "Nine",
                    'translate.2_intro_easy.guest.option10': "Zero",

                    'translate.2_intro_easy.timer.title': "Intro 2 - A Case in Paradise",
                    'translate.2_intro_easy.timer.subtitle': "The introduction puzzle will start in:",
                    'translate.2_intro_easy.player.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
                    'translate.2_intro_easy.player.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_easy.player.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_easy.player.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
                    'translate.2_intro_easy.guest.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
                    'translate.2_intro_easy.guest.hint.hint1': "The first digit is 5.",
                    'translate.2_intro_easy.guest.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
                    'translate.2_intro_easy.guest.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
                    'translate.2_intro_easy.player.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
                    'translate.2_intro_easy.player.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
                    'translate.2_intro_easy.player.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_easy.player.message3.title': "That's right! You will now continue to the real game. Have fun together!",
                    'translate.2_intro_easy.guest.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
                    'translate.2_intro_easy.guest.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
                    'translate.2_intro_easy.guest.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
                    'translate.2_intro_easy.guest.message3.title': "That's right! You will now continue to the real game. Have fun together!",

                    'translate.the_knight_standard.timer.title': "1. The Knight - A Case In Paradise",
                    'translate.the_knight_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_knight_standard.message1.title': "Let’s figure out what happened here. We need to compare how it looks now with the camera recordings from yesterday. I wrote down the items. {{playerName}}, can you find which items on your list were moved?",
                    'translate.the_knight_standard.message2.title': "Ask {{partnerName}} for the letters of their items and let me know what changed.",
                    'translate.the_knight_standard.message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
                    'translate.the_knight_standard.message3.title': "Thank you. I will try to make sense of what happened here.",
                    'translate.the_knight_standard.player.timer.message1': 'There are five items that have been moved.',
                    'translate.the_knight_standard.player.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
                    'translate.the_knight_standard.player.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
                    'translate.the_knight_standard.player.hint.hint1': "There are five items that have been moved.",
                    'translate.the_knight_standard.player.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
                    'translate.the_knight_standard.player.hint.solution': "B F G M S. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

                    'translate.the_knight_standard.guest_message1.title': "Let’s figure out what happened here. We need to compare how it looks now with the camera recordings from yesterday. I wrote down the items. {{playerName}}, can you find which items on your list were moved?",
                    'translate.the_knight_standard.guest_message2.title': "Ask {{partnerName}} for the letters of their items and let me know what changed.",
                    'translate.the_knight_standard.guest_message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
                    'translate.the_knight_standard.guest_message3.title': "Thank you. I will try to make sense of what happened here.",
                    'translate.the_knight_standard.guest.timer.message1': 'There are five items that have been moved.',
                    'translate.the_knight_standard.guest.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
                    'translate.the_knight_standard.guest.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
                    'translate.the_knight_standard.guest.hint.hint1': "There are five items that have been moved.",
                    'translate.the_knight_standard.guest.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
                    'translate.the_knight_standard.guest.hint.solution': "B F G M S. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

                    'translate.the_knight_challenging.timer.title': "1. The Knight - A Case In Paradise",
                    'translate.the_knight_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_knight_challenging.message1.title': "Let’s figure out what happened here. I wrote down the items. {{playerName}}, can you find which items changed on your list).",
                    'translate.the_knight_challenging.message2.title': "Ask {{partnerName}}for the numbers of their items and let me know what changed.",
                    'translate.the_knight_challenging.message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
                    'translate.the_knight_challenging.message3.title': "Thank you. I will try to make sense of what happened here.",
                    'translate.the_knight_challenging.player.timer.message1': 'There are five items that have been moved.',
                    'translate.the_knight_challenging.player.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
                    'translate.the_knight_challenging.player.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
                    'translate.the_knight_challenging.player.hint.hint1': "There are five items that have been moved.",
                    'translate.the_knight_challenging.player.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
                    'translate.the_knight_challenging.player.hint.solution': "B F G M S. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

                    'translate.the_knight_challenging.guest_message1.title': "Let’s figure out what happened here. I wrote down the items. {{playerName}}, can you find which items changed on your list).",
                    'translate.the_knight_challenging.guest_message2.title': "Ask {{partnerName}}for the numbers of their items and let me know what changed.",
                    'translate.the_knight_challenging.guest_message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
                    'translate.the_knight_challenging.guest_message3.title': "Thank you. I will try to make sense of what happened here.",
                    'translate.the_knight_challenging.guest.timer.message1': 'There are five items that have been moved.',
                    'translate.the_knight_challenging.guest.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
                    'translate.the_knight_challenging.guest.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
                    'translate.the_knight_challenging.guest.hint.hint1': "There are five items that have been moved.",
                    'translate.the_knight_challenging.guest.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
                    'translate.the_knight_challenging.guest.hint.solution': "B F G M S. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

                    'translate.the_blackout_standard.timer.title': "2. The Blackout - A Case in Paradise",
                    'translate.the_blackout_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_blackout_standard.message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout. The owner sent me some complaints from the guests with his notes on them. Lovely man.",
                    'translate.the_blackout_standard.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. You will probably want to make some notes yourself as well.",
                    'translate.the_blackout_standard.message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.the_blackout_standard.message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
                    'translate.the_blackout_standard.player.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
                    'translate.the_blackout_standard.player.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
                    'translate.the_blackout_standard.player.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. Focus first on finding out how to turn on the fan in the hallway.",
                    'translate.the_blackout_standard.player.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
                    'translate.the_blackout_standard.player.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
                    'translate.the_blackout_standard.player.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",
                    'translate.the_blackout_standard.guest_message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout. The owner sent me some complaints from the guests with his notes on them. Lovely man.",
                    'translate.the_blackout_standard.guest_message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. You will probably want to make some notes yourself as well.",
                    'translate.the_blackout_standard.guest_message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.the_blackout_standard.guest_message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
                    'translate.the_blackout_standard.guest.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
                    'translate.the_blackout_standard.guest.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
                    'translate.the_blackout_standard.guest.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. Focus first on finding out how to turn on the fan in the hallway.",
                    'translate.the_blackout_standard.guest.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
                    'translate.the_blackout_standard.guest.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
                    'translate.the_blackout_standard.guest.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",

                    'translate.the_blackout_challenging.timer.title': "2. The Blackout - A Case in Paradise",
                    'translate.the_blackout_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_blackout_challenging.message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout. The owner sent me some complaints from the guests with his notes on them. Lovely man.",
                    'translate.the_blackout_challenging.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. You will probably want to make some notes yourself as well.",
                    'translate.the_blackout_challenging.message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.the_blackout_challenging.message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
                    'translate.the_blackout_challenging.player.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
                    'translate.the_blackout_challenging.player.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
                    'translate.the_blackout_challenging.player.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. Focus first on finding out how to turn on the fan in the hallway.",
                    'translate.the_blackout_challenging.player.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
                    'translate.the_blackout_challenging.player.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
                    'translate.the_blackout_challenging.player.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",
                    'translate.the_blackout_challenging.guest_message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout. The owner sent me some complaints from the guests with his notes on them. Lovely man.",
                    'translate.the_blackout_challenging.guest_message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. You will probably want to make some notes yourself as well.",
                    'translate.the_blackout_challenging.guest_message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
                    'translate.the_blackout_challenging.guest_message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
                    'translate.the_blackout_challenging.guest.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
                    'translate.the_blackout_challenging.guest.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
                    'translate.the_blackout_challenging.guest.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. Focus first on finding out how to turn on the fan in the hallway.",
                    'translate.the_blackout_challenging.guest.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
                    'translate.the_blackout_challenging.guest.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
                    'translate.the_blackout_challenging.guest.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",

                    'translate.the_witnesses_standard.timer.title': "3. The Witnesses - A Case in Paradise",
                    'translate.the_witnesses_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_witnesses_standard.message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
                    'translate.the_witnesses_standard.message2.title': "Place the names in the order of the rooms (please note, this way of entering a solution is work in progress and for this test only).",
                    'translate.the_witnesses_standard.message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
                    'translate.the_witnesses_standard.message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
                    'translate.the_witnesses_standard.player.timer.message1': 'Justin is in room 2.',
                    'translate.the_witnesses_standard.player.timer.message2': 'Room 3 and room 5 are unknown',
                    'translate.the_witnesses_standard.player.hint.explanation': "You need to place each guest in the right room. Start with Anna Marquez who is staying in room 1 and focus first on figuring out which people are on the top or bottom floor.",
                    'translate.the_witnesses_standard.player.hint.hint1': "Justin is in room 2.",
                    'translate.the_witnesses_standard.player.hint.hint2': "Room 3 and room 5 are unknown.",
                    'translate.the_witnesses_standard.player.hint.solution': "1: Daniel. 2 Justin. 3: Unknown. 4: Gabrielle. 5. Unknown 6. Thomas. 7. Jane. 8. Liz. Anna (last surname as Daniel) tells you that she is in room 201 and that there is laundry room on the top floor where machines made a lot of noise and someone was cursing (this must be Justin). Daniel says he he heard no one below him, that his wife is in the room with him and that she makes noise. Justin hears yelling in spanish in the next room, so he is next door (in 2). Gabrielle didn’t hear anything next or below her, so she must be in room (4). Liz hears an opera singer, so she is below Gabrielle (8).Thomas makes noise and hears noise above him, so he can only be below Justin (Daniel would have heard him otherwise) in room 6. Jane hears nothing in the room above,  she must be in 7, if she was in 5 she would have hear arguing in Spanish.",
                    'translate.the_witnesses_standard.guest_message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
                    'translate.the_witnesses_standard.guest_message2.title': "Place the names in the order of the rooms (please note, this way of entering a solution is work in progress and for this test only).",
                    'translate.the_witnesses_standard.guest_message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
                    'translate.the_witnesses_standard.guest_message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
                    'translate.the_witnesses_standard.guest.timer.message1': 'Justin is in room 2.',
                    'translate.the_witnesses_standard.guest.timer.message2': 'Room 3 and room 5 are unknown',
                    'translate.the_witnesses_standard.guest.hint.explanation': "You need to place each guest in the right room. Start with Anna Marquez who is staying in room 1 and focus first on figuring out which people are on the top or bottom floor. ",
                    'translate.the_witnesses_standard.guest.hint.hint1': "Justin is in room 2.",
                    'translate.the_witnesses_standard.guest.hint.hint2': "Room 3 and room 5 are unknown.",
                    'translate.the_witnesses_standard.guest.hint.solution': "1: Daniel. 2 Justin. 3: Unknown. 4: Gabrielle. 5. Unknown 6. Thomas. 7. Jane. 8. Liz. Anna (last surname as Daniel) tells you that she is in room 201 and that there is laundry room on the top floor where machines made a lot of noise and someone was cursing (this must be Justin). Daniel says he he heard no one below him, that his wife is in the room with him and that she makes noise. Justin hears yelling in spanish in the next room, so he is next door (in 2). Gabrielle didn’t hear anything next or below her, so she must be in room (4). Liz hears an opera singer, so she is below Gabrielle (8).Thomas makes noise and hears noise above him, so he can only be below Justin (Daniel would have heard him otherwise) in room 6. Jane hears nothing in the room above,  she must be in 7, if she was in 5 she would have hear arguing in Spanish.",

                    'translate.the_witnesses_challenging.timer.title': "3. The Witnesses - A Case in Paradise",
                    'translate.the_witnesses_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_witnesses_challenging.message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
                    'translate.the_witnesses_challenging.message2.title': "Place the names in the order of the rooms (please note, this way of entering a solution is work in progress and for this test only).",
                    'translate.the_witnesses_challenging.message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
                    'translate.the_witnesses_challenging.message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
                    'translate.the_witnesses_challenging.player.timer.message1': 'Justin is in room 2.',
                    'translate.the_witnesses_challenging.player.timer.message2': 'Room 3 and room 5 are unknown',
                    'translate.the_witnesses_challenging.player.hint.explanation': "You need to place each guest in the right room. Start with Anna Marquez who is staying in room 1 and focus first on figuring out which people are on the top or bottom floor.",
                    'translate.the_witnesses_challenging.player.hint.hint1': "Justin is in room 2.",
                    'translate.the_witnesses_challenging.player.hint.hint2': "Room 3 and room 5 are unknown.",
                    'translate.the_witnesses_challenging.player.hint.solution': "1: Daniel. 2 Justin. 3: Unknown. 4: Gabrielle. 5. Unknown 6. Thomas. 7. Jane. 8. Liz. Anna (last surname as Daniel) tells you that she is in room 201 and that there is laundry room on the top floor where machines made a lot of noise and someone was cursing (this must be Justin). Daniel says he he heard no one below him, that his wife is in the room with him and that she makes noise. Justin hears yelling in spanish in the next room, so he is next door (in 2). Gabrielle didn’t hear anything next or below her, so she must be in room (4). Liz hears an opera singer, so she is below Gabrielle (8).Thomas makes noise and hears noise above him, so he can only be below Justin (Daniel would have heard him otherwise) in room 6. Jane hears nothing in the room above,  she must be in 7, if she was in 5 she would have hear arguing in Spanish.",
                    'translate.the_witnesses_challenging.guest_message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
                    'translate.the_witnesses_challenging.guest_message2.title': "Place the names in the order of the rooms (please note, this way of entering a solution is work in progress and for this test only).",
                    'translate.the_witnesses_challenging.guest_message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
                    'translate.the_witnesses_challenging.guest_message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
                    'translate.the_witnesses_challenging.guest.timer.message1': 'Justin is in room 2.',
                    'translate.the_witnesses_challenging.guest.timer.message2': 'Room 3 and room 5 are unknown',
                    'translate.the_witnesses_challenging.guest.hint.explanation': "You need to place each guest in the right room. Start with Anna Marquez who is staying in room 1 and focus first on figuring out which people are on the top or bottom floor. ",
                    'translate.the_witnesses_challenging.guest.hint.hint1': "Justin is in room 2.",
                    'translate.the_witnesses_challenging.guest.hint.hint2': "Room 3 and room 5 are unknown.",
                    'translate.the_witnesses_challenging.guest.hint.solution': "1: Daniel. 2 Justin. 3: Unknown. 4: Gabrielle. 5. Unknown 6. Thomas. 7. Jane. 8. Liz. Anna (last surname as Daniel) tells you that she is in room 201 and that there is laundry room on the top floor where machines made a lot of noise and someone was cursing (this must be Justin). Daniel says he he heard no one below him, that his wife is in the room with him and that she makes noise. Justin hears yelling in spanish in the next room, so he is next door (in 2). Gabrielle didn’t hear anything next or below her, so she must be in room (4). Liz hears an opera singer, so she is below Gabrielle (8).Thomas makes noise and hears noise above him, so he can only be below Justin (Daniel would have heard him otherwise) in room 6. Jane hears nothing in the room above,  she must be in 7, if she was in 5 she would have hear arguing in Spanish.",

                    'translate.the_key_standard.timer.title': "4. The Key - A Case in Paradise",
                    'translate.the_key_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_key_standard.message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
                    'translate.the_key_standard.message2.title': "Who had their hands on the key? And in what order?",
                    'translate.the_key_standard.message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
                    'translate.the_key_standard.message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
                    'translate.the_key_standard.player.timer.message1': 'The key changed hands 4 times, so you’ll have to enter 5 names.',
                    'translate.the_key_standard.player.timer.message2': 'The lady took the bottle opener twice. The Boy is the only one who never had the key.',
                    'translate.the_key_standard.player.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
                    'translate.the_key_standard.player.hint.hint1': "The key changed hands 4 times, so you’ll have to enter 5 names.",
                    'translate.the_key_standard.player.hint.hint2': "The lady took the bottle opener twice. The Boy is the only one who never had the key.",
                    'translate.the_key_standard.player.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",
                    'translate.the_key_standard.guest_message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
                    'translate.the_key_standard.guest_message2.title': "Who had their hands on the key? And in what order?",
                    'translate.the_key_standard.guest_message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
                    'translate.the_key_standard.guest_message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
                    'translate.the_key_standard.guest.timer.message1': 'The key changed hands 4 times, so you’ll have to enter 5 names.',
                    'translate.the_key_standard.guest.timer.message2': 'The lady took the bottle opener twice. The Boy is the only one who never had the key.',
                    'translate.the_key_standard.guest.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
                    'translate.the_key_standard.guest.hint.hint1': "The key changed hands 4 times, so you’ll have to enter 5 names.",
                    'translate.the_key_standard.guest.hint.hint2': "The lady took the bottle opener twice. The Boy is the only one who never had the key.",
                    'translate.the_key_standard.guest.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",

                    'translate.the_key_challenging.timer.title': "4. The Key - A Case in Paradise",
                    'translate.the_key_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_key_challenging.message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
                    'translate.the_key_challenging.message2.title': "Who had their hands on the key? And in what order?",
                    'translate.the_key_challenging.message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
                    'translate.the_key_challenging.message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
                    'translate.the_key_challenging.player.timer.message1': 'The key changed hands 4 times, so you’ll have to enter 5 names.',
                    'translate.the_key_challenging.player.timer.message2': 'The lady took the bottle opener twice. The Boy is the only one who never had the key.',
                    'translate.the_key_challenging.player.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
                    'translate.the_key_challenging.player.hint.hint1': "The key changed hands 4 times, so you’ll have to enter 5 names.",
                    'translate.the_key_challenging.player.hint.hint2': "The lady took the bottle opener twice. The Boy is the only one who never had the key.",
                    'translate.the_key_challenging.player.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",
                    'translate.the_key_challenging.guest_message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
                    'translate.the_key_challenging.guest_message2.title': "Who had their hands on the key? And in what order?",
                    'translate.the_key_challenging.guest_message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
                    'translate.the_key_challenging.guest_message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
                    'translate.the_key_challenging.guest.timer.message1': 'The key changed hands 4 times, so you’ll have to enter 5 names.',
                    'translate.the_key_challenging.guest.timer.message2': 'The lady took the bottle opener twice. The Boy is the only one who never had the key.',
                    'translate.the_key_challenging.guest.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
                    'translate.the_key_challenging.guest.hint.hint1': "The key changed hands 4 times, so you’ll have to enter 5 names.",
                    'translate.the_key_challenging.guest.hint.hint2': "The lady took the bottle opener twice. The Boy is the only one who never had the key.",
                    'translate.the_key_challenging.guest.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",

                    'translate.the_interrogation_standard.timer.title': "5. The Interrogation - A Case in Paradise",
                    'translate.the_interrogation_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_interrogation_standard.message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
                    'translate.the_interrogation_standard.message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
                    'translate.the_interrogation_standard.message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
                    'translate.the_interrogation_standard.message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
                    'translate.the_interrogation_standard.player.timer.message1': 'There are five things that she lies about.',
                    'translate.the_interrogation_standard.player.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
                    'translate.the_interrogation_standard.player.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
                    'translate.the_interrogation_standard.player.hint.hint1': "There are five things that she lies about.",
                    'translate.the_interrogation_standard.player.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
                    'translate.the_interrogation_standard.player.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",
                    'translate.the_interrogation_standard.guest_message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
                    'translate.the_interrogation_standard.guest_message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
                    'translate.the_interrogation_standard.guest_message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
                    'translate.the_interrogation_standard.guest_message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
                    'translate.the_interrogation_standard.guest.timer.message1': 'There are five things that she lies about.',
                    'translate.the_interrogation_standard.guest.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
                    'translate.the_interrogation_standard.guest.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
                    'translate.the_interrogation_standard.guest.hint.hint1': "There are five things that she lies about.",
                    'translate.the_interrogation_standard.guest.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
                    'translate.the_interrogation_standard.guest.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",

                    'translate.the_interrogation_challenging.timer.title': "5. The Interrogation - A Case in Paradise",
                    'translate.the_interrogation_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_interrogation_challenging.message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
                    'translate.the_interrogation_challenging.message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
                    'translate.the_interrogation_challenging.message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
                    'translate.the_interrogation_challenging.message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
                    'translate.the_interrogation_challenging.player.timer.message1': 'There are five things that she lies about.',
                    'translate.the_interrogation_challenging.player.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
                    'translate.the_interrogation_challenging.player.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
                    'translate.the_interrogation_challenging.player.hint.hint1': "There are five things that she lies about.",
                    'translate.the_interrogation_challenging.player.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
                    'translate.the_interrogation_challenging.player.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",
                    'translate.the_interrogation_challenging.guest_message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
                    'translate.the_interrogation_challenging.guest_message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
                    'translate.the_interrogation_challenging.guest_message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
                    'translate.the_interrogation_challenging.guest_message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
                    'translate.the_interrogation_challenging.guest.timer.message1': 'There are five things that she lies about.',
                    'translate.the_interrogation_challenging.guest.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
                    'translate.the_interrogation_challenging.guest.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
                    'translate.the_interrogation_challenging.guest.hint.hint1': "There are five things that she lies about.",
                    'translate.the_interrogation_challenging.guest.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
                    'translate.the_interrogation_challenging.guest.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",

                    'translate.the_card_game_standard.timer.title': "6. The Card Game - A Case in Paradise",
                    'translate.the_card_game_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_card_game_standard.message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
                    'translate.the_card_game_standard.message2.title': "{{playerName}}, you play as the Boy and {{partnerName}}, you play as Peach. Let me know which cards were played in order for one person to win all 5 rounds. (FOR TESTERS, EACH ROUND THE CARDS ARE PLAYED AT THE SAME TIME BUT YOU NEED TO ENTER FIRST THE BOY AND THEN PEACH, FOR EXAMPLE AVBWCWDYEZ).",
                    'translate.the_card_game_standard.message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the TAG ANSWER link above.",
                    'translate.the_card_game_standard.message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
                    'translate.the_card_game_standard.player.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
                    'translate.the_card_game_standard.player.timer.message2': 'The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
                    'translate.the_card_game_standard.player.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
                    'translate.the_card_game_standard.player.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
                    'translate.the_card_game_standard.player.hint.hint2': "The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
                    'translate.the_card_game_standard.player.hint.solution': "CW, EZ, DX, BV, AY. The Lady plays a Jack of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay under the 10 of hearts of Peach. The boy has to play a Hearts, because Hearts is played by the dealer. The boy does not have any Spades, so on the second turn, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he has to play Jack of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen of Clubs.",
                    'translate.the_card_game_standard.guest_message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
                    'translate.the_card_game_standard.guest_message2.title': "{{playerName}}, you play as Peach and {{partnerName}}, you play as the Boy. Let me know which cards were played in order for one person to win all 5 rounds. (FOR TESTERS, EACH ROUND THE CARDS ARE PLAYED AT THE SAME TIME BUT YOU NEED TO ENTER FIRST THE BOY AND THEN PEACH, FOR EXAMPLE AVBWCWDYEZ).",
                    'translate.the_card_game_standard.guest_message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the TAG ANSWER link above.",
                    'translate.the_card_game_standard.guest_message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
                    'translate.the_card_game_standard.guest.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
                    'translate.the_card_game_standard.guest.timer.message2': 'The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
                    'translate.the_card_game_standard.guest.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
                    'translate.the_card_game_standard.guest.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
                    'translate.the_card_game_standard.guest.hint.hint2': "The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
                    'translate.the_card_game_standard.guest.hint.solution': "CW, EZ, DX, BV, AY. The Lady plays a Jack of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay under the 10 of hearts of Peach. The boy has to play a Hearts, because Hearts is played by the dealer. The boy does not have any Spades, so on the second turn, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he has to play Jack of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen of Clubs.",

                    'translate.the_card_game_challenging.timer.title': "6. The Card Game - A Case in Paradise",
                    'translate.the_card_game_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_card_game_challenging.message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
                    'translate.the_card_game_challenging.message2.title': "{{playerName}}, you play as the Boy and {{partnerName}}, you play as Peach. Let me know which cards were played in order for one person to win all 5 rounds. (FOR TESTERS, EACH ROUND THE CARDS ARE PLAYED AT THE SAME TIME BUT YOU NEED TO ENTER FIRST THE BOY AND THEN PEACH, FOR EXAMPLE AVBWCWDYEZ).",
                    'translate.the_card_game_challenging.message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the TAG ANSWER link above.",
                    'translate.the_card_game_challenging.message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
                    'translate.the_card_game_challenging.player.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
                    'translate.the_card_game_challenging.player.timer.message2': 'The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
                    'translate.the_card_game_challenging.player.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
                    'translate.the_card_game_challenging.player.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
                    'translate.the_card_game_challenging.player.hint.hint2': "The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
                    'translate.the_card_game_challenging.player.hint.solution': "CW, EZ, DX, BV, AY. The Lady plays a Jack of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay under the 10 of hearts of Peach. The boy has to play a Hearts, because Hearts is played by the dealer. The boy does not have any Spades, so on the second turn, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he has to play Jack of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen of Clubs.",
                    'translate.the_card_game_challenging.guest_message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
                    'translate.the_card_game_challenging.guest_message2.title': "{{playerName}}, you play as Peach and {{partnerName}}, you play as the Boy. Let me know which cards were played in order for one person to win all 5 rounds. (FOR TESTERS, EACH ROUND THE CARDS ARE PLAYED AT THE SAME TIME BUT YOU NEED TO ENTER FIRST THE BOY AND THEN PEACH, FOR EXAMPLE AVBWCWDYEZ).",
                    'translate.the_card_game_challenging.guest_message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the TAG ANSWER link above.",
                    'translate.the_card_game_challenging.guest_message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
                    'translate.the_card_game_challenging.guest.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
                    'translate.the_card_game_challenging.guest.timer.message2': 'The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
                    'translate.the_card_game_challenging.guest.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
                    'translate.the_card_game_challenging.guest.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
                    'translate.the_card_game_challenging.guest.hint.hint2': "The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
                    'translate.the_card_game_challenging.guest.hint.solution': "CW, EZ, DX, BV, AY. The Lady plays a Jack of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay under the 10 of hearts of Peach. The boy has to play a Hearts, because Hearts is played by the dealer. The boy does not have any Spades, so on the second turn, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he has to play Jack of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen of Clubs.",

                    'translate.the_missing_items_standard.timer.title': "7. The Missing Items - A Case in Paradise",
                    'translate.the_missing_items_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_missing_items_standard.message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
                    'translate.the_missing_items_standard.message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you the list of her favorite colours. {{partnerName}} I sent you a list from the owner. (Note for testers, enter the solution in the order of the list from the owner).",
                    'translate.the_missing_items_standard.message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
                    'translate.the_missing_items_standard.message3.title': "At least one mystery is solved. Strange way to impress someone...",
                    'translate.the_missing_items_standard.player.timer.message1': 'There is a red dress with dots lying on the sofa.',
                    'translate.the_missing_items_standard.player.timer.message2': 'TThere is a white lipstick with TP on it lying on the sofa.',
                    'translate.the_missing_items_standard.player.hint.explanation': "You need to find which items match both descriptions from the owner and from peach. For example if there is something yellow with dots, the first letter of the code would be F (because 1. is something with dots). You only have to use each color and each description once.",
                    'translate.the_missing_items_standard.player.hint.hint1': "There is a red dress with dots lying on the sofa.",
                    'translate.the_missing_items_standard.player.hint.hint2': "There is a white lipstick with TP on it lying on the sofa.",
                    'translate.the_missing_items_standard.player.hint.solution': "The answer is BEDCFA. A red dress with dots on it, white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, and yellow lipstick with a rectangular shape and a golden bracelet with a pearl on it.",
                    'translate.the_missing_items_standard.guest_message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
                    'translate.the_missing_items_standard.guest_message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you a list from the owner with the description of the items. {{partnerName}} I sent you a list with the favorite colors from Peach. (Note for testers, enter the solution in the order on the list of the owner).",
                    'translate.the_missing_items_standard.guest_message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
                    'translate.the_missing_items_standard.guest_message3.title': "At least one mystery is solved. Strange way to impress someone...",
                    'translate.the_missing_items_standard.guest.timer.message1': 'There is a red dress with dots lying on the sofa.',
                    'translate.the_missing_items_standard.guest.timer.message2': 'TThere is a white lipstick with TP on it lying on the sofa.',
                    'translate.the_missing_items_standard.guest.hint.explanation': "You need to find which items match both descriptions from the owner and from peach. For example if there is something yellow with dots, the first letter of the code would be F (because 1. is something with dots). You only have to use each color and each description once.",
                    'translate.the_missing_items_standard.guest.hint.hint1': "There is a red dress with dots lying on the sofa.",
                    'translate.the_missing_items_standard.guest.hint.hint2': "There is a white lipstick with TP on it lying on the sofa.",
                    'translate.the_missing_items_standard.hint.solution': "The answer is BEDCFA. A red dress with dots on it, white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, and yellow lipstick with a rectangular shape and a golden bracelet with a pearl on it.",

                    'translate.the_missing_items_challenging.timer.title': "7. The Missing Items - A Case in Paradise",
                    'translate.the_missing_items_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_missing_items_challenging.message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
                    'translate.the_missing_items_challenging.message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you the list of her favorite colours. {{partnerName}} I sent you a list from the owner. (Note for testers, enter the solution in the order of the list from the owner).",
                    'translate.the_missing_items_challenging.message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
                    'translate.the_missing_items_challenging.message3.title': "At least one mystery is solved. Strange way to impress someone...",
                    'translate.the_missing_items_challenging.player.timer.message1': 'There is a red dress with dots lying on the sofa.',
                    'translate.the_missing_items_challenging.player.timer.message2': 'TThere is a white lipstick with TP on it lying on the sofa.',
                    'translate.the_missing_items_challenging.player.hint.explanation': "You need to find which items match both descriptions from the owner and from peach. For example if there is something yellow with dots, the first letter of the code would be F (because 1. is something with dots). You only have to use each color and each description once.",
                    'translate.the_missing_items_challenging.player.hint.hint1': "There is a red dress with dots lying on the sofa.",
                    'translate.the_missing_items_challenging.player.hint.hint2': "There is a white lipstick with TP on it lying on the sofa.",
                    'translate.the_missing_items_challenging.player.hint.solution': "The answer is BEDCFA. A red dress with dots on it, white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, and yellow lipstick with a rectangular shape and a golden bracelet with a pearl on it.",
                    'translate.the_missing_items_challenging.guest_message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
                    'translate.the_missing_items_challenging.guest_message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you a list from the owner with the description of the items. {{partnerName}} I sent you a list with the favorite colors from Peach. (Note for testers, enter the solution in the order on the list of the owner).",
                    'translate.the_missing_items_challenging.guest_message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
                    'translate.the_missing_items_challenging.guest_message3.title': "At least one mystery is solved. Strange way to impress someone...",
                    'translate.the_missing_items_challenging.guest.timer.message1': 'There is a red dress with dots lying on the sofa.',
                    'translate.the_missing_items_challenging.guest.timer.message2': 'TThere is a white lipstick with TP on it lying on the sofa.',
                    'translate.the_missing_items_challenging.guest.hint.explanation': "You need to find which items match both descriptions from the owner and from peach. For example if there is something yellow with dots, the first letter of the code would be F (because 1. is something with dots). You only have to use each color and each description once.",
                    'translate.the_missing_items_challenging.guest.hint.hint1': "There is a red dress with dots lying on the sofa.",
                    'translate.the_missing_items_challenging.guest.hint.hint2': "There is a white lipstick with TP on it lying on the sofa.",
                    'translate.the_missing_items_challenging.hint.solution': "The answer is BEDCFA. A red dress with dots on it, white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, and yellow lipstick with a rectangular shape and a golden bracelet with a pearl on it.",

                    'translate.the_traces_standard.timer.title': "8. Traces - A Case in Paradise",
                    'translate.the_traces_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_traces_standard.message1.title': "I just lost him. Pretty fast runner… ",
                    'translate.the_traces_standard.message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left and let me know the locations in the right order. I know he started near rooms 161-169.",
                    'translate.the_traces_standard.message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
                    'translate.the_traces_standard.message3.title': "That means he ended up with on shoe in the Attic. Let’s see if we can find him there",
                    'translate.the_traces_standard.player.timer.message1': 'There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.',
                    'translate.the_traces_standard.player.timer.message2': 'You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. ',
                    'translate.the_traces_standard.player.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
                    'translate.the_traces_standard.player.hint.hint1': "There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.",
                    'translate.the_traces_standard.player.hint.hint2': "You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26.",
                    'translate.the_traces_standard.player.hint.solution': "(161-169) (261-269). (151-159). Attic B. (251-259). (241-249). (141-149). Attic A. He starts in room 162 (there are no traces there). You see the first traces on near room 262. After he knocks over a vase, he leaves a trace of dried flowers. You see only these traces near room 151. On attic B you see a trace of dried flowers, a bucket that has been kicked over and then a trace of both dried flowers and wet footsteps. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",
                    'translate.the_traces_standard.guest_message1.title': "I just lost him. Pretty fast runner… ",
                    'translate.the_traces_standard.guest_message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left and let me know the locations in the right order. I know he started near rooms 161-169.",
                    'translate.the_traces_standard.guest_message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
                    'translate.the_traces_standard.guest_message3.title': "That means he ended up with on shoe in the Attic. Let’s see if we can find him there",
                    'translate.the_traces_standard.guest.timer.message1': 'There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.',
                    'translate.the_traces_standard.guest.timer.message2': 'You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. ',
                    'translate.the_traces_standard.guest.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
                    'translate.the_traces_standard.guest.hint.hint1': "There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.",
                    'translate.the_traces_standard.guest.hint.hint2': "You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26.",
                    'translate.the_traces_standard.guest.hint.solution': "(161-169) (261-269). (151-159). Attic B. (251-259). (241-249). (141-149). Attic A. He starts in room 162 (there are no traces there). You see the first traces on near room 262. After he knocks over a vase, he leaves a trace of dried flowers. You see only these traces near room 151. On attic B you see a trace of dried flowers, a bucket that has been kicked over and then a trace of both dried flowers and wet footsteps. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",

                    'translate.the_traces_challenging.timer.title': "8. Traces - A Case in Paradise",
                    'translate.the_traces_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_traces_challenging.message1.title': "I just lost him. Pretty fast runner… ",
                    'translate.the_traces_challenging.message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left and let me know the locations in the right order. I know he started near rooms 161-169.",
                    'translate.the_traces_challenging.message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
                    'translate.the_traces_challenging.message3.title': "That means he ended up with on shoe in the Attic. Let’s see if we can find him there",
                    'translate.the_traces_challenging.player.timer.message1': 'There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.',
                    'translate.the_traces_challenging.player.timer.message2': 'You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. ',
                    'translate.the_traces_challenging.player.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
                    'translate.the_traces_challenging.player.hint.hint1': "There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.",
                    'translate.the_traces_challenging.player.hint.hint2': "You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26.",
                    'translate.the_traces_challenging.player.hint.solution': "(161-169) (261-269). (151-159). Attic B. (251-259). (241-249). (141-149). Attic A. He starts in room 162 (there are no traces there). You see the first traces on near room 262. After he knocks over a vase, he leaves a trace of dried flowers. You see only these traces near room 151. On attic B you see a trace of dried flowers, a bucket that has been kicked over and then a trace of both dried flowers and wet footsteps. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",
                    'translate.the_traces_challenging.guest_message1.title': "I just lost him. Pretty fast runner… ",
                    'translate.the_traces_challenging.guest_message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left and let me know the locations in the right order. I know he started near rooms 161-169.",
                    'translate.the_traces_challenging.guest_message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
                    'translate.the_traces_challenging.guest_message3.title': "That means he ended up with on shoe in the Attic. Let’s see if we can find him there",
                    'translate.the_traces_challenging.guest.timer.message1': 'There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.',
                    'translate.the_traces_challenging.guest.timer.message2': 'You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. ',
                    'translate.the_traces_challenging.guest.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
                    'translate.the_traces_challenging.guest.hint.hint1': "There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.",
                    'translate.the_traces_challenging.guest.hint.hint2': "You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26.",
                    'translate.the_traces_challenging.guest.hint.solution': "(161-169) (261-269). (151-159). Attic B. (251-259). (241-249). (141-149). Attic A. He starts in room 162 (there are no traces there). You see the first traces on near room 262. After he knocks over a vase, he leaves a trace of dried flowers. You see only these traces near room 151. On attic B you see a trace of dried flowers, a bucket that has been kicked over and then a trace of both dried flowers and wet footsteps. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",

                    'translate.the_security_tapes_standard.timer.title': "9. The Tapes- A Case in Paradise",
                    'translate.the_security_tapes_standard.timer.subtitle': "The challenge will start in:",
                    'translate.the_security_tapes_standard.message1.title': "Lets see what’s on those tapes. We need to figure out where everybody was before the blackout started.",
                    'translate.the_security_tapes_standard.message2.title': "Let me know which rooms they ended up in, in the same order that they got there. (FOR TESTERS note that if you use a room multiple times in the solution, use the (1st) version first and the (2nd) version second.)",
                    'translate.the_security_tapes_standard.message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?  Use the TAG ANSWER link above.",
                    'translate.the_security_tapes_standard.message3.title': "Interesting. Now let’s see if we can find out who did it.",
                    'translate.the_security_tapes_standard.player.timer.message1': 'The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.',
                    'translate.the_security_tapes_standard.player.timer.message2': 'The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.',
                    'translate.the_security_tapes_standard.player.hint.explanation': "You need to figure out in which room they started, where they ended up right before the blackout and in which order they end up there. Focus on one person at a time to find out what happened.",
                    'translate.the_security_tapes_standard.player.hint.hint1': "The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.",
                    'translate.the_security_tapes_standard.player.hint.hint2': "The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
                    'translate.the_security_tapes_standard.player.hint.solution': "1. Bar (Caretaker). 2 Bar (Baron) 3. 248 (Peach). 4. Office (Owner). 5. Hallway (Lady). 6. Lobby (Boy). You see the caretaker appear first and move to the bar. At that point the baron is still standing there. You see the owner move the baron to the bar. After that you first see Peach over from the bar to upstairs (but she never appears on camera near room 274, so she must be in room 248. When the lady moves down you see the owner, who goes in the office before she enters the lobby. Finally you see the Boy who follows the Lady.",
                    'translate.the_security_tapes_standard.guest_message1.title': "Lets see what’s on those tapes. We need to figure out where everybody was before the blackout started.",
                    'translate.the_security_tapes_standard.guest_message2.title': "Let me know which rooms they ended up in, in the same order that they got there. (FOR TESTERS note that if you use a room multiple times in the solution, use the (1st) version first and the (2nd) version second.) ",
                    'translate.the_security_tapes_standard.guest_message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?  Use the TAG ANSWER link above.",
                    'translate.the_security_tapes_standard.guest_message3.title': "Interesting. Now let’s see if we can find out who did it.",
                    'translate.the_security_tapes_standard.guest.timer.message1': 'The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.',
                    'translate.the_security_tapes_standard.guest.timer.message2': 'The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.',
                    'translate.the_security_tapes_standard.guest.hint.explanation': "You need to figure out in which room they started, where they ended up right before the blackout and in which order they end up there. Focus on one person at a time to find out what happened.",
                    'translate.the_security_tapes_standard.guest.hint.hint1': "The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.",
                    'translate.the_security_tapes_standard.guest.hint.hint2': "The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
                    'translate.the_security_tapes_standard.guest.hint.solution': "1. Bar (Caretaker). 2 Bar (Baron) 3. 248 (Peach). 4. Office (Owner). 5. Hallway (Lady). 6. Lobby (Boy). You see the caretaker appear first and move to the bar. At that point the baron is still standing there. You see the owner move the baron to the bar. After that you first see Peach over from the bar to upstairs (but she never appears on camera near room 274, so she must be in room 248. When the lady moves down you see the owner, who goes in the office before she enters the lobby. Finally you see the Boy who follows the Lady.",

                    'translate.the_security_tapes_challenging.timer.title': "9. The Tapes- A Case in Paradise",
                    'translate.the_security_tapes_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.the_security_tapes_challenging.message1.title': "Lets see what’s on those tapes. We need to figure out where everybody was before the blackout started.",
                    'translate.the_security_tapes_challenging.message2.title': "Let me know which rooms they ended up in, in the same order that they got there. (FOR TESTERS note that if you use a room multiple times in your solution, use the (1st) version first and the (2nd) version second.) ",
                    'translate.the_security_tapes_challenging.message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?  Use the TAG ANSWER link above.",
                    'translate.the_security_tapes_challenging.message3.title': "Interesting. Now let’s see if we can find out who did it.",
                    'translate.the_security_tapes_challenging.player.timer.message1': 'The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.',
                    'translate.the_security_tapes_challenging.player.timer.message2': 'The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.',
                    'translate.the_security_tapes_challenging.player.hint.explanation': "You need to figure out in which room they started, where they ended up right before the blackout and in which order they end up there. Focus on one person at a time to find out what happened.",
                    'translate.the_security_tapes_challenging.player.hint.hint1': "The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.",
                    'translate.the_security_tapes_challenging.player.hint.hint2': "The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
                    'translate.the_security_tapes_challenging.player.hint.solution': "1. Bar (Caretaker). 2 Bar (Baron) 3. 248 (Peach). 4. Office (Owner). 5. Hallway (Lady). 6. Lobby (Boy). You see the caretaker appear first and move to the bar. At that point the baron is still standing there. You see the owner move the baron to the bar. After that you first see Peach over from the bar to upstairs (but she never appears on camera near room 274, so she must be in room 248. When the lady moves down you see the owner, who goes in the office before she enters the lobby. Finally you see the Boy who follows the Lady.",
                    'translate.the_security_tapes_challenging.guest_message1.title': "Lets see what’s on those tapes. We need to figure out where everybody was before the blackout started.",
                    'translate.the_security_tapes_challenging.guest_message2.title': "Let me know which rooms they ended up in, in the same order that they got there.(FOR TESTERS note that if you use a room multiple times in your solution, use the (1st) version first and the (2nd) version second.) ",
                    'translate.the_security_tapes_challenging.guest_message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?  Use the TAG ANSWER link above.",
                    'translate.the_security_tapes_challenging.guest_message3.title': "Interesting. Now let’s see if we can find out who did it.",
                    'translate.the_security_tapes_challenging.guest.timer.message1': 'The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.',
                    'translate.the_security_tapes_challenging.guest.timer.message2': 'The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.',
                    'translate.the_security_tapes_challenging.guest.hint.explanation': "You need to figure out in which room they started, where they ended up right before the blackout and in which order they end up there. Focus on one person at a time to find out what happened.",
                    'translate.the_security_tapes_challenging.guest.hint.hint1': "The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.",
                    'translate.the_security_tapes_challenging.guest.hint.hint2': "The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
                    'translate.the_security_tapes_challenging.guest.hint.solution': "1. Bar (Caretaker). 2 Bar (Baron) 3. 248 (Peach). 4. Office (Owner). 5. Hallway (Lady). 6. Lobby (Boy). You see the caretaker appear first and move to the bar. At that point the baron is still standing there. You see the owner move the baron to the bar. After that you first see Peach over from the bar to upstairs (but she never appears on camera near room 274, so she must be in room 248. When the lady moves down you see the owner, who goes in the office before she enters the lobby. Finally you see the Boy who follows the Lady.",

                    'translate.whodunnit_standard.timer.title': "10. Whodunnit - A Case in Paradise",
                    'translate.whodunnit_standard.timer.subtitle': "The challenge will start in:",
                    'translate.whodunnit_standard.message1.title': "We have all the facts. It is time to put it all together.",
                    'translate.whodunnit_standard.message2.title': "Let me know what you think happened. (FOR TESTERS the first time you use a word, please use the (1st) version, the second time the (2nd) version and so on).",
                    'translate.whodunnit_standard.message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
                    'translate.whodunnit_standard.message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
                    'translate.whodunnit_standard.player.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.',
                    'translate.whodunnit_standard.player.timer.message2': 'There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?',
                    'translate.whodunnit_standard.player.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
                    'translate.whodunnit_standard.player.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.",
                    'translate.whodunnit_standard.player.hint.hint2': "There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?",
                    'translate.whodunnit_standard.player.hint.solution': "(1) The Caretaker and (2) the Lady planned to steal the totem.  But the real thief knew of their plans. The thief planned to use (3) chloroform to take out (4) the Lady, but when the blackout started the Boy (5) was in his way. When the thief approached the totem room, the Lady (6) got scared and hid in a closet. The thief pushed over a bowl, the Lady (6) screamed, alerting the Owner (7). The thief had no choice but to escape by (8) the window. When the thief re-entered, they left a trail which led to the (9 bar. There, the thief hid his (10) shoes because on the video the Baron shows is white socks while he had shoes on before the blackout. So we know the thief is the baron (11).",
                    'translate.whodunnit_standard.guest_message1.title': "We have all the facts. It is time to put it all together.",
                    'translate.whodunnit_standard.guest_message2.title': "Let me know what you think happened. (FOR TESTERS the first time you use a word, please use the (1st) version, the second time the (2nd) version and so on). ",
                    'translate.whodunnit_standard.guest_message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
                    'translate.whodunnit_standard.guest_message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
                    'translate.whodunnit_standard.guest.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.',
                    'translate.whodunnit_standard.guest.timer.message2': 'There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?',
                    'translate.whodunnit_standard.guest.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
                    'translate.whodunnit_standard.guest.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.",
                    'translate.whodunnit_standard.guest.hint.hint2': "There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?",
                    'translate.whodunnit_standard.guest.hint.solution': "(1) The Caretaker and (2) the Lady planned to steal the totem.  But the real thief knew of their plans. The thief planned to use (3) chloroform to take out (4) the Lady, but when the blackout started the Boy (5) was in his way. When the thief approached the totem room, the Lady (6) got scared and hid in a closet. The thief pushed over a bowl, the Lady (6) screamed, alerting the Owner (7). The thief had no choice but to escape by (8) the window. When the thief re-entered, they left a trail which led to the (9 bar. There, the thief hid his (10) shoes because on the video the Baron shows is white socks while he had shoes on before the blackout. So we know the thief is the baron (11).",

                    'translate.whodunnit_challenging.timer.title': "10. Whodunnit - A Case in Paradise",
                    'translate.whodunnit_challenging.timer.subtitle': "The challenge will start in:",
                    'translate.whodunnit_challenging.message1.title': "We have all the facts. It is time to put it all together.",
                    'translate.whodunnit_challenging.message2.title': "Let me know what you think happened. (FOR TESTERS the first time you use a word, please use the (1st) version, the second time the (2nd) version and so on).",
                    'translate.whodunnit_challenging.message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
                    'translate.whodunnit_challenging.message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
                    'translate.whodunnit_challenging.player.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.',
                    'translate.whodunnit_challenging.player.timer.message2': 'There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?',
                    'translate.whodunnit_challenging.player.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
                    'translate.whodunnit_challenging.player.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.",
                    'translate.whodunnit_challenging.player.hint.hint2': "There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?",
                    'translate.whodunnit_challenging.player.hint.solution': "(1) The Caretaker and (2) the Lady planned to steal the totem.  But the real thief knew of their plans. The thief planned to use (3) chloroform to take out (4) the Lady, but when the blackout started the Boy (5) was in his way. When the thief approached the totem room, the Lady (6) got scared and hid in a closet. The thief pushed over a bowl, the Lady (6) screamed, alerting the Owner (7). The thief had no choice but to escape by (8) the window. When the thief re-entered, they left a trail which led to the (9 bar. There, the thief hid his (10) shoes because on the video the Baron shows is white socks while he had shoes on before the blackout. So we know the thief is the baron (11).",
                    'translate.whodunnit_challenging.guest_message1.title': "We have all the facts. It is time to put it all together.",
                    'translate.whodunnit_challenging.guest_message2.title': "Let me know what you think happened. (FOR TESTERS the first time you use a word, please use the (1st) version, the second time the (2nd) version and so on).",
                    'translate.whodunnit_challenging.guest_message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
                    'translate.whodunnit_challenging.guest_message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
                    'translate.whodunnit_challenging.guest.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.',
                    'translate.whodunnit_challenging.guest.timer.message2': 'There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?',
                    'translate.whodunnit_challenging.guest.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
                    'translate.whodunnit_challenging.guest.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.",
                    'translate.whodunnit_challenging.guest.hint.hint2': "There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?",
                    'translate.whodunnit_challenging.guest.hint.solution': "(1) The Caretaker and (2) the Lady planned to steal the totem.  But the real thief knew of their plans. The thief planned to use (3) chloroform to take out (4) the Lady, but when the blackout started the Boy (5) was in his way. When the thief approached the totem room, the Lady (6) got scared and hid in a closet. The thief pushed over a bowl, the Lady (6) screamed, alerting the Owner (7). The thief had no choice but to escape by (8) the window. When the thief re-entered, they left a trail which led to the (9 bar. There, the thief hid his (10) shoes because on the video the Baron shows is white socks while he had shoes on before the blackout. So we know the thief is the baron (11).",








                },
                tagOptionsDialog: {
                    availableOptions: "Available options ({{count}})",
                    submitButton: "Submit",
                    clearBtn: "Clear",
                },
                taggerCarousel: {
                    altText: "tag image",
                    confirmButton: "Confirm",
                    submitButtonText: "Submit",
                    tagAllImages: "Tag all the images"
                },
                resultsDialog: {
                    resultsTitle: "Results",
                    resultsScoreDifficultyCap: "You are two smart detectives! We believe you deserve a higher difficulty level. The puzzles will probably take you a little bit longer, but you will earn more points. The main player can change the difficulty level in the menu, so you can always go back to standard difficulty!",
                    hintsUsed: "Total Hints used",
                    incorrectScore: "Total Incorrect score",
                    solutionsTried: "Total Solutions tried",
                    timeSpent: "Time Spent",
                    totalPlayerHintsUsed: "Total Player Hints Used",
                    totalGuestHintsUsed: "Total Guest Hints Used",
                    totalPlayerSolutionsTried: "Total Incorrect Attempts",
                    grandTotalResults: "Grand total results",
                    totalScore: "Total score",
                    congratulationsText: "Congratulations! You've successfully cracked the case together!",
                    waitMessage: "Wait for the other player to press results and try again!",
                    goToMenu: "Go To Menu",
                    continueButton: "Continue",
                    curiousFriends: "Do you want to know your friends score?",
                    shareWithFriends: "Invite them and see how they did!",
                    shareMessage: "{{playerName}} and {{partnerName}} challenge you for a tv escape room! They scored {{score}} for {{puzzleName}} on {{gameName}}. Do you accept this challenge? Go to {{link}}",
                    shareGrandTotalMessage: "{{playerName}} and {{partnerName}} challenge you for a tv escape room! They scored {{score}} for on {{gameName}}. Do you accept this challenge? Go to {{link}}"
                },
                continuePuzzleVideo: {
                    'Intro 1': 'Are you ready? Then start the next video - Intro 2 - and press Continue!',
                    'Intro 2': 'Are you ready to start the game? Then start the next video - 1. The Knight - and press Continue!',
                    'The Blackout': 'Are you ready for the next challenge? Then start the next video  - 3. The Witnesses - and press Continue!',
                    'The Traces': 'Are you ready for the next challenge? Then start the next video - 9. The Security Tapes - and press Continue!',
                    'The Key': 'Are you ready for the next challenge? Then start the next video -  5. The Interrogation - and press Continue!',
                    'Whodunnit': 'Well done, you solved it together! Press Continue to watch your total score and challenge your friends!',
                    'The Missing Items': 'Are you ready for the next challenge? Then start the next video -  8. The Traces - and press Continue!',
                    'The Knight': 'Are you ready for the next challenge? Then start the next video  - 2. The Blackout - and press Continue. ',
                    'The Witnesses': 'Are you ready for the next challenge? Then start the next video  - 4. The Key - and press Continue!',
                    'The Interrogation': 'Are you both ready for the next challenge? Then start the next video  - 6. The Card Game - and press Continue!',
                    'The Security Tapes': 'Are you ready for the next challenge? Then start the next video - 10. Whodunnit - and press Continue!',
                    'The Card Game': 'Are you ready for the next challenge? Then start the next video  - 7. The Missing Items - and press Continue!',
                },
                puzzleCard: {
                    difficultyPlayed: "Difficulty played:",
                    hintsUsedByPlayer: "Number of hints used by the player:",
                    hintsUsedByGuest: "Number of hints used by guest:",
                    solutionsTriedByPlayer: "Number of solutions tried by the player:",
                    solutionsTriedByGuest: "Number of solutions tried by the guest:",
                    expires: "Expires",
                    seeStats: "See stats"
                },
                problemModal: {
                    sorry: "We're really sorry about the hiccup! But don't worry, we're here to guide it back on track.",
                    clickContinue: "By clicking continue, you will reset this challenge and you should be able to continue.",
                    areYouSure: "Are you sure?",
                    reset: "Reset",
                    guestNeedRejoin: "The guest needs to re-join the game again. Please share the link with the guest player and ask them to join.",
                    inviteContinue: "Invited, continue!",
                    doneOrSupport: "That's it! Now let's reload the page and the game will go on. In case this issue persist, contact the support.",
                    reloadPage: "Reload page",
                    problem: "Problem?",
                },
                instructionsModal: {
                    instruction1: "How to play<br>" +
                        "<br>A Toxic Case is a two player game about a missing person case. To play the game, both players need their own smartphone. Each player will receive different information about the case and a question they will need to answer." +
                        "<br>Together you will watch a video about the case. The goal is to answer the question by discussing the information you received with the other player." +
                        "<br>The videos are shown on our CouchClues Youtube Channel. We suggest using a television, but you can watch them on any screen you like." +
                        "<br>Each video has an introduction, which is part of the story and then a case you will need to solve. Each case is repeated multiple times, so you won't have to restart the video.",
                    instruction2: "Rules and options<br>" +
                        "<br>When you start a puzzle, a timer will start. You can try to solve the puzzles as fast as possible in order to receive the most points. Or you can ignore the score and take as long as you like." +
                        "<br>If you don't understand a puzzle, you can get a hint. Each puzzle has an explanation hint, which tells you more about what you need to do, and 2 hints that help you with the actual puzzle." +
                        "<br>Both players can use the same hint or you can read the hint again, without using additional points." +
                        "<br>If the hints are not enough to solve the puzzle, you can click the Solution option to read the answer.",
                    instruction3: "Starting the game<br>" +
                        "<br>The player that has purchased the game will receive a game link, which will give them access to the game. This will be the main player." +
                        "<br>When the main player starts the game, they will be asked to share a second link with the other player. This second link allows the other player to join as guest and enter their own name." +
                        "<br>The game will start with 2 intro challenges to get you acquainted with the game" +
                        "<br>If you experience bugs or other problems, the main player can use the 'Problem' button in this menu to get you back on track.",
                    btnText: 'Continue'
                },
                navbar: {
                    inviteFriend: "Invite a friend",
                    instructions: "Instructions",
                    instructionsUrl: "https://couchclues.com/instructions/",
                    problem: "Problem?",
                    quit: "Quit",
                },
                difficultyModal: {
                    areYouSure: "Are you sure you want to change difficulty to",
                    dialogContent: "By changing the difficulty you will reset your current challenge. \n Your guest player will need to reload the page to continue. Are you sure you want to proceed?",
                    cancel: "Cancel",
                    confirm: "Confirm",
                }
            },
            nl: {
                resultsNavigation: {
                    faqUrl: "https://couchclues.com/nl/#faq",
                    shopUrl: "https://couchclues.com/nl/chapters/a-toxic-case/full/"
                },
                game: {
                    language: "Taal",
                    languageEn: "English",
                    languageNl: "Nederlands",
                    continue: "DOORGAAN!",
                    playNow: "Speel Nu",
                    seeResults: "Bekijk de score",
                    selectLanguage: "Selecteer de taal",
                    go: "NU!",
                    results: "Score",
                    completed: "Voltooid:",
                    noPuzzles: "Geen puzzels beschikbaar. Koop een spel om te beginnen met spelen!",
                    typeToAnswer: "Typ hier het antwoord",
                    clickToTag: "Klik om afbeeldingen te taggen",
                    clickToTagAnswer: "Klik om antwoorden te taggen",
                    clickToTagAnswerInOrder: "Tag de antwoorden in de juiste volgorde",
                    backToChat: "Terug naar de chat",
                    chatWith: "Chatten met",
                    difficulty: "Moeilijkheidsgraad",
                    copyLink: "Kopieer deze link en deel dit met je medespeler:",
                    copyLinkLabel: "Uitnodigingslink kopiëren",
                    inviteFriend: "Nodig een vriend uit",
                    about: "Over",
                    faq: "FAQ",
                    shop: "Shop",
                    waitForGo: "Wacht tot de andere speler op NU! drukt",
                    shareAlinkWithPlayer: "Deel een link om een andere speler uit te nodigen",
                    askPartnerToStart: "Vraag je medespeler om het spel te starten. We wachten...",
                    guestPlayerNotReady: "Je medespeler is nog niet klaar. We wachten... ",
                    minimumPlayersText: "Je hebt minimaal twee spelers nodig om het spel te spelen. {!isGuest ? ' Stuur de link naar je medespeler om te kunnen spelen ' : ''}",
                    copyInviteLinkLabel: "Uitnodigingslink kopiëren",
                    playerWaitingForPartner: " {{partnerName}} moet nog een oplossing doorsturen. Kun jij helpen?",
                    difficultyLabel: "Moeilijkheidsgraad",
                    startButtonText: "Start het spel",
                    playButtonText: "Spelen!",
                    shareLinkDialogTitle: "Deel de link hieronder om het spel te starten",
                    startGameDialogTitle: "Los het samen op!",
                    yourNameLabel: "Jouw naam",
                    getStartText: "Start het spel",
                    errorGoHome: "Terug naar Home",
                    error: "Fout",
                    notExists: "Het spel waarnaar je op zoek bent bestaat niet",
                    buyAnother: "Je moet een ander hoofdstuk kopen om dit spel te spelen",
                    youDontHave: "Je hebt geen toegang tot deze puzzel",
                    getPuzzle: "Koop hoofdstuk",
                },
                puzzle: {
                    // dutch media starts ====
                    'translate.game.paradise': 'A Toxic Case',

                    'translate.the_blackout_standard.player.media2': "https://couchclues.com/wp-content/uploads/nl2smainprescription.webp",
                    'translate.the_blackout_standard.player.media3': "https://couchclues.com/wp-content/uploads/nl2smainoneheart.webp",
                    'translate.the_blackout_standard.player.media4': "https://couchclues.com/wp-content/uploads/nl2smaintattooface.webp",
                    'translate.the_blackout_standard.guest.media2': "https://couchclues.com/wp-content/uploads/nl2sguestglasses.webp",
                    'translate.the_blackout_standard.guest.media3': "https://couchclues.com/wp-content/uploads/nl2sguestearring.webp",
                    'translate.the_blackout_standard.guest.media4': "https://couchclues.com/wp-content/uploads/nl2sguestrahuljim.webp",
                    'translate.the_blackout_standard.guest.media5': "https://couchclues.com/wp-content/uploads/nl2sguest30years.webp",

                    'translate.the_blackout_challenging.player.media2': "https://couchclues.com/wp-content/uploads/nl2mainage.webp",
                    'translate.the_blackout_challenging.player.media3': "https://couchclues.com/wp-content/uploads/nl2mainnotblonde.webp",
                    'translate.the_blackout_challenging.player.media4': "https://couchclues.com/wp-content/uploads/nl2mainnotfamily.webp",
                    'translate.the_blackout_challenging.player.media5': "https://couchclues.com/wp-content/uploads/nl2mainoneheart.webp",
                    'translate.the_blackout_challenging.player.media6': "https://couchclues.com/wp-content/uploads/nl2mainrahul.webp",
                    'translate.the_blackout_challenging.guest.media2': "https://couchclues.com/wp-content/uploads/nl2guestglasses.webp",
                    'translate.the_blackout_challenging.guest.media3': "https://couchclues.com/wp-content/uploads/nl2guestheart.webp",
                    'translate.the_blackout_challenging.guest.media4': "https://couchclues.com/wp-content/uploads/nl2guestmedicines.webp",
                    'translate.the_blackout_challenging.guest.media5': "https://couchclues.com/wp-content/uploads/nl2guestnephew.webp",
                    'translate.the_blackout_challenging.guest.media6': "https://couchclues.com/wp-content/uploads/nl2guesttattoos.webp",

                    'translate.whodunnit_standard.player.media': "https://couchclues.com/wp-content/uploads/nl10sboth.webp",
                    'translate.whodunnit_standard.guest.media': "https://couchclues.com/wp-content/uploads/nl10sboth.webp",

                    'translate.whodunnit_challenging.player.media': "https://couchclues.com/wp-content/uploads/nl10main.webp",
                    'translate.whodunnit_challenging.guest.media': "https://couchclues.com/wp-content/uploads/nl10guest.webp",

                    'translate.the_knight_standard.player.media': "https://couchclues.com/wp-content/uploads/nl1both.webp",
                    'translate.the_knight_standard.player.media2': "https://couchclues.com/wp-content/uploads/nl1main.webp",
                    'translate.the_knight_standard.guest.media': "https://couchclues.com/wp-content/uploads/nl1both.webp",
                    'translate.the_knight_standard.guest.media2': "https://couchclues.com/wp-content/uploads/nl1guest.webp",

                    'translate.the_knight_challenging.player.media': "https://couchclues.com/wp-content/uploads/nl1both.webp",
                    'translate.the_knight_challenging.player.media2': "https://couchclues.com/wp-content/uploads/nl1main.webp",
                    'translate.the_knight_challenging.guest.media': "https://couchclues.com/wp-content/uploads/nl1both.webp",
                    'translate.the_knight_challenging.guest.media2': "https://couchclues.com/wp-content/uploads/nl1guest.webp",

                    'translate.the_witnesses_standard.player.media': "https://couchclues.com/wp-content/uploads/nl3smap.webp",
                    'translate.the_witnesses_standard.player.media2': "https://couchclues.com/wp-content/uploads/nl3mainsymbols.webp",
                    'translate.the_witnesses_standard.player.media3': "https://couchclues.com/wp-content/uploads/nl3mainitems.webp",
                    'translate.the_witnesses_standard.player.media4': "https://couchclues.com/wp-content/uploads/nl3marker.webp",
                    'translate.the_witnesses_standard.guest.media': "https://couchclues.com/wp-content/uploads/nl3smap.webp",
                    'translate.the_witnesses_standard.guest.media2': "https://couchclues.com/wp-content/uploads/nl3guestsymbols.webp",
                    'translate.the_witnesses_standard.guest.media3': "https://couchclues.com/wp-content/uploads/nl3guestitems.webp",
                    'translate.the_witnesses_standard.guest.media4': "https://couchclues.com/wp-content/uploads/nl3marker.webp",

                    'translate.the_witnesses_challenging.player.media': "https://couchclues.com/wp-content/uploads/nl3smap.webp",
                    'translate.the_witnesses_challenging.player.media2': "https://couchclues.com/wp-content/uploads/nl3mainsymbols.webp",
                    'translate.the_witnesses_challenging.player.media3': "https://couchclues.com/wp-content/uploads/nl3mainitems.webp",
                    'translate.the_witnesses_challenging.player.media4': "https://couchclues.com/wp-content/uploads/nl3marker.webp",
                    'translate.the_witnesses_challenging.guest.media': "https://couchclues.com/wp-content/uploads/nl3smap.webp",
                    'translate.the_witnesses_challenging.guest.media2': "https://couchclues.com/wp-content/uploads/nl3guestsymbols.webp",
                    'translate.the_witnesses_challenging.guest.media3': "https://couchclues.com/wp-content/uploads/nl3guestitems.webp",
                    'translate.the_witnesses_challenging.guest.media4': "https://couchclues.com/wp-content/uploads/nl3marker.webp",

                    'translate.the_interrogation_standard.player.media': "https://couchclues.com/wp-content/uploads/nl5both.webp",
                    'translate.the_interrogation_standard.player.media2': "https://couchclues.com/wp-content/uploads/nl5main.webp",
                    'translate.the_interrogation_standard.guest.media': "https://couchclues.com/wp-content/uploads/nl5both.webp",
                    'translate.the_interrogation_standard.guest.media2': "https://couchclues.com/wp-content/uploads/nl5guest.webp",

                    'translate.the_interrogation_challenging.player.media': "https://couchclues.com/wp-content/uploads/nl5both.webp",
                    'translate.the_interrogation_challenging.player.media2': "https://couchclues.com/wp-content/uploads/nl5main.webp",
                    'translate.the_interrogation_challenging.guest.media': "https://couchclues.com/wp-content/uploads/nl5both.webp",
                    'translate.the_interrogation_challenging.guest.media2': "https://couchclues.com/wp-content/uploads/nl5guest.webp",
                    // dutch media ends ====
                    'translate.game.STANDARD': 'Standaard',
                    'translate.game.CHALLENGING': 'Uitdagend',

                    'translate.game.hint.solution.label': "Oplossing",
                    'translate.game.hint.explanation.label': "Uitleg",
                    'translate.game.hint.hints.label': "Hints",
                    'translate.game.hint.hint1.label': "Hint 1",
                    'translate.game.hint.hint2.label': "Hint 2",
                    'translate.game.puzzle.timer.watch_video': "Bekijk de volgende video:",

                    'translate.game.hint.explanation_minus_points.label': "Uitleg",
                    'translate.game.hint.hint1_minus_points.label': "Hint 1",
                    'translate.game.hint.hint2_minus_points.label': "Hint 2",

                    'translate.game.paradise.puzzle_name.intro1': "Intro 1",
                    'translate.game.paradise.puzzle_name.intro2': "Intro 2",
                    'translate.game.paradise.puzzle_name.the_knight': "The Knight",
                    'translate.game.paradise.puzzle_name.the_blackout': "The Blackout",
                    'translate.game.paradise.puzzle_name.the_witnesses': "The Witnesses",
                    'translate.game.paradise.puzzle_name.the_key': "The Key",
                    'translate.game.paradise.puzzle_name.the_interrogation': "The Interrogation",
                    'translate.game.paradise.puzzle_name.the_card_game': "The Card Game",
                    'translate.game.paradise.puzzle_name.the_missing_items': "The Missing Items",
                    'translate.game.paradise.puzzle_name.the_traces': "The Traces",
                    'translate.game.paradise.puzzle_name.the_security_tapes': "The Security Tapes",
                    'translate.game.paradise.puzzle_name.whodunnit': "Whodunnit",

                    'translate.game.messages.answers.waiting': "OK, ik weet wat ik moet doen. Laat {{partnerName}} weten dat ik klaar sta als jullie het teken geven.",
                    'translate.game.messages.answers.incorrectWhenOpponentCorrect': "{{partnerName}} heeft me ook iets geappt.",
                    'translate.game.messages.answers.bothIncorrect': "Ik heb ernaar gekeken, maar het lijkt niet te kloppen...",
                    'translate.game.messages.answers.opponentCorrect': "Jouw oplossing werkte niet, maar die van {{partnerName}} wel!",
                    'translate.game.messages.answers.diffAnswersImRightButWaiting': "Wat jij zegt klinkt logisch, maar wat {{partnerName}} appt niet. Help {{partnerName}} en geef me het teken als jullie het eens zijn.",

                    'translate.game.score.65': "Niet slecht! Jullie scoren beter dan 50% van alle spelers",
                    'translate.game.score.75': "Netjes! Jullie hebben een betere score dan 75% van alle spelers",
                    'translate.game.score.80': "Goede score! Jullie zitten in de top 20%!",
                    'translate.game.score.85': "Hele goede score! Jullie horen bij de beste 15% voor deze opdracht",
                    'translate.game.score.90': "Fantastische score! Jullie zitten bij de top 10% voor deze opdracht",
                    'translate.game.score.95': "Bijna perfect! Jullie zitten bij de top 5% voor deze opdracht!",
                    'translate.game.score.100': "Waanzinnig! Maar 1% van de spelers krijgt een score van 100 voor deze puzzel.",
                    'translate.game.score.140': "Niet slecht! Jullie scoren beter dan 50% van de spelers op deze moeilijkheidsgraad",
                    'translate.game.score.150': "Netjes! Jullie hebben een betere score dan 75% van alle spelers op deze moeilijkheidsgraad",
                    'translate.game.score.160': "Mooi resultaat! Jullie zitten bij de top 25% van de spelers die op deze moeilijkheidsgraad spelen",
                    'translate.game.score.170': "Goede score! Jullie zitten in de top 20 van de spelers die op Uitdagend spelen",
                    'translate.game.score.180': "Hele goede score! Jullie horen bij de beste 15% voor dit hoofdstuk op Uitdagend",
                    'translate.game.score.190': "Fantastische score! Jullie zitten bij de top 10% voor dit hoofdstuk op Uitdagend",
                    'translate.game.score.195': "Bijna perfect! Jullie zitten bij de top 5% voor deze puzzel! En dat op Uitdagend!",
                    'translate.game.score.200': "Waanzinnig! Jullie horen bij de allerbeste ter wereld voor deze opdracht!",
                    'translate.game.score.241': "Impressive! Better than 50% of players on 'crazy' difficulty",
                    'translate.game.score.250': "Nice! You scored better then 55% of players on 'crazy' difficulty level",
                    'translate.game.score.260': "Well done! You are in the top 25% for this challenge (on \"crazy\" level)",
                    'translate.game.score.270': "Good score: You two in the top 20% (for \"crazy\" difficulty)",
                    'translate.game.score.280': "Very good score! You are in the top 15% for this challenge (on \"crazy \"difficulty",
                    'translate.game.score.290': "Great score! You are in the top 10% for this challenge (on \"crazy\" difficulty)",
                    'translate.game.score.295': "You are the crazy geniuses within the crazy geniuses group! You are in the top 5%",
                    'translate.game.score.300': "Wow! OK, there are 2 options. You are crazy geniuses, or you are cheating. But we don't want to tell crazy geniuses that they are cheating, so well done!",

                    'translate.1_intro_simple.player.timer.message1': 'Hey player, maybe you need some help?',
                    'translate.1_intro_simple.player.timer.message2': 'It is hard huh? Keep up!',
                    'translate.1_intro_simple.player.timer.message3': 'Tick tack tick tack, maybe you need to use a hint?',
                    'translate.1_intro_simple.guest.timer.message1': 'Hey guest, maybe you need some help?',
                    'translate.1_intro_simple.player.hint.explanation': "Voor iedere opdracht is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en echt geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je medespeler hetzelfde 3-cijferige getal vinden. Klik hiervoor allebij op Hint 1.",
                    'translate.1_intro_simple.player.hint.hint2': "Het eerste cijfer is een 9. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
                    'translate.1_intro_simple.player.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het eerste cijfer een 9 is. De eerste hint voor je medespeler was dat het laatste cijfer een 6 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_simple.player.hint.hint3': "Heeft je medespeler het laatste cijfer verteld? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",
                    'translate.1_intro_simple.guest.hint.explanation': "Voor iedere opdracht is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en echt geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
                    'translate.1_intro_simple.guest.hint.hint2': "Het laatste cijfer is een 6. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
                    'translate.1_intro_simple.guest.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het laatste cijfer een 6 is. De eerste hint voor je medespeler was dat het eerste cijfer een 9 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_simple.guest.hint.hint3': "Heeft je medespeler verteld wat het eerste cijfer is? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",

                    'translate.1_intro_simple.player.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_simple.player.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_simple.player.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie - A Toxic Case'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_simple.player.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

                    'translate.1_intro_simple.guest.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_simple.guest.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_simple.guest.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar 'Introductie'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_simple.guest.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

                    'translate.1_intro_easy.player.hint.explanation': "Voor alle opdrachten is een uitleg beschikbaar. Deze kun je gebruiken als vastzit aan het begin van een opdracht en je geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
                    'translate.1_intro_easy.player.hint.hint2': "Het eerste cijfer is een 9. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
                    'translate.1_intro_easy.player.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het eerste cijfer een 9 is. De eerste hint voor je medespeler was dat het laatste cijfer een 6 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_easy.player.hint.hint3': "Heeft je medespeler het laatste cijfer verteld? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",
                    'translate.1_intro_easy.guest.hint.explanation': "Voor alle opdrachten is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en je geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
                    'translate.1_intro_easy.guest.hint.hint2': "Het laatste cijfer is een 6. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
                    'translate.1_intro_easy.guest.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het laatste cijfer een 6 is. De eerste hint voor je medespeler was dat het eerste cijfer een 9 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
                    'translate.1_intro_easy.guest.hint.hint3': "Heeft je medespeler verteld wat het eerste cijfer is? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",

                    'translate.1_intro_easy.player.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_easy.player.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_easy.player.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie' ? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_easy.player.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

                    'translate.1_intro_easy.guest.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
                    'translate.1_intro_easy.guest.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
                    'translate.1_intro_easy.guest.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
                    'translate.1_intro_easy.guest.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",


                    'translate.2_intro_simple.player.option1': "Een",
                    'translate.2_intro_simple.player.option2': "Twee",
                    'translate.2_intro_simple.player.option3': "Drie",
                    'translate.2_intro_simple.player.option4': "Vier",
                    'translate.2_intro_simple.player.option5': "Vijf",
                    'translate.2_intro_simple.player.option6': "Zes",
                    'translate.2_intro_simple.player.option7': "Zeven",
                    'translate.2_intro_simple.player.option8': "Acht",
                    'translate.2_intro_simple.player.option9': "Negen",
                    'translate.2_intro_simple.player.option10': "Nul",
                    'translate.2_intro_simple.guest.option1': "Een",
                    'translate.2_intro_simple.guest.option2': "Twee",
                    'translate.2_intro_simple.guest.option3': "Drie",
                    'translate.2_intro_simple.guest.option4': "Vier",
                    'translate.2_intro_simple.guest.option5': "Vijf",
                    'translate.2_intro_simple.guest.option6': "Zes",
                    'translate.2_intro_simple.guest.option7': "Zeven",
                    'translate.2_intro_simple.guest.option8': "Acht",
                    'translate.2_intro_simple.guest.option9': "Negen",
                    'translate.2_intro_simple.guest.option10': "Nul",
                    'translate.2_intro_simple.timer.title': "Intro 2 - A Toxic Case",
                    'translate.2_intro_simple.timer.subtitle': "De oefenopdracht start over:",
                    'translate.2_intro_simple.player.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat koppelen aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_simple.player.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_simple.player.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_simple.player.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_simple.guest.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat koppelen aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_simple.guest.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_simple.guest.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_simple.guest.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_simple.player.message1.title': "Nu is het tijd voor een oefenopdracht. Jullie zien informatie in de Intro 2 video en een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_simple.player.message2.title': "Jullie doel is om een 4 cijferige code te vinden. Klik op TAG ANTWOORDEN om het antwoord door te geven.",
                    'translate.2_intro_simple.player.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_simple.player.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",
                    'translate.2_intro_simple.guest.message1.title': "Nu is het tijd voor een oefenopdracht. Jullie zien informatie in de Intro 2 video en een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_simple.guest.message2.title': "Jullie doel is om een 4 cijferige code te vinden. Klik op TAG ANTWOORDEN om het antwoord door te geven.",
                    'translate.2_intro_simple.guest.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_simple.guest.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",

                    'translate.2_intro_easy.player.option1': "Een",
                    'translate.2_intro_easy.player.option2': "Twee",
                    'translate.2_intro_easy.player.option3': "Drie",
                    'translate.2_intro_easy.player.option4': "Vier",
                    'translate.2_intro_easy.player.option5': "Vijf",
                    'translate.2_intro_easy.player.option6': "Zes",
                    'translate.2_intro_easy.player.option7': "Zeven",
                    'translate.2_intro_easy.player.option8': "Acht",
                    'translate.2_intro_easy.player.option9': "Negen",
                    'translate.2_intro_easy.player.option10': "Nul",
                    'translate.2_intro_easy.guest.option1': "Een",
                    'translate.2_intro_easy.guest.option2': "Twee",
                    'translate.2_intro_easy.guest.option3': "Drie",
                    'translate.2_intro_easy.guest.option4': "Vier",
                    'translate.2_intro_easy.guest.option5': "Vijf",
                    'translate.2_intro_easy.guest.option6': "Zes",
                    'translate.2_intro_easy.guest.option7': "Zeven",
                    'translate.2_intro_easy.guest.option8': "Acht",
                    'translate.2_intro_easy.guest.option9': "Negen",
                    'translate.2_intro_easy.guest.option10': "Nul",
                    'translate.2_intro_easy.player.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en linken aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_easy.player.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_easy.player.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_easy.player.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_easy.guest.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat linken aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
                    'translate.2_intro_easy.guest.hint.hint1': "Het eerste getal is 5.",
                    'translate.2_intro_easy.guest.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
                    'translate.2_intro_easy.guest.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
                    'translate.2_intro_easy.player.message1.title': "Nu is het tijd voor een oefenpuzzel. Jullie zien een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_easy.player.message2.title': "Kijk naar de 'Intro 2' video. Je doel is om een 4 cijferige code te vinden.",
                    'translate.2_intro_easy.player.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_easy.player.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",
                    'translate.2_intro_easy.guest.message1.title': "Nu is het tijd voor een oefenpuzzel. Jullie zien een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
                    'translate.2_intro_easy.guest.message2.title': "Kijk naar de 'Intro 2' video. Je doel is om een 4 cijferige code te vinden.",
                    'translate.2_intro_easy.guest.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
                    'translate.2_intro_easy.guest.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",

                    'translate.the_blackout_standard.timer.title': "2. Deceased persons - A Toxic Case",
                    'translate.the_blackout_standard.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_blackout_standard.player.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Hij wist van 9 personen zeker dat ze niet vergiftigd waren. De notitie dat maar 1 van de vergiftigde personen een hartaandoening heeft is blijkbaar heel belangrijk.',
                    'translate.the_blackout_standard.player.timer.message2': 'Op de achterkant van de foto van Simon Carlson stond dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
                    'translate.the_blackout_standard.guest.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Hij wist van 9 personen zeker dat ze niet vergiftigd waren. De notitie dat maar 1 van de vergiftigde personen een hartaandoening heeft is blijkbaar heel belangrijk.',
                    'translate.the_blackout_standard.guest.timer.message2': 'Op de achterkant van de foto van Simon Carlson stond dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',

                    'translate.the_blackout_standard.player.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Probeer eerst de mensen te vinden die zeker niet vergiftigd zijn.",
                    'translate.the_blackout_standard.player.hint.hint1': "9 personen zijn zeker niet vergiftigd. Mensen met tattoages op hun gezicht, met een bril (ongeluk, dus niet vergiftigd) en Jim en Rahul (gebruiken geen medicatie).",
                    'translate.the_blackout_standard.player.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson. Dit betekent dat de andere persoon geen hartaandoening heeft.",
                    'translate.the_blackout_standard.player.hint.solution': "Simon en Sam. <br> De mensen met een tattoage in hun gezicht en de personen met een bril (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul en Jim (gebruiken geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee resterende personen Simon. De jongere persoon kan dan geen hartaandoening (oorbel) hebben. Dus valt Anna af en bijft Sam over.",
                    'translate.the_blackout_standard.guest.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Probeer eerst de mensen te vinden die zeker niet vergiftigd zijn.",
                    'translate.the_blackout_standard.guest.hint.hint1': "9 personen zijn zeker niet vergiftigd. Mensen met tattoages op hun gezicht, met een bril (ongeluk, dus niet vergiftigd) en Jim en Rahul (gebruiken geen medicatie)..",
                    'translate.the_blackout_standard.guest.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson. Dit betekent dat de andere persoon geen hartaandoening heeft.",
                    'translate.the_blackout_standard.guest.hint.solution': "Simon en Sam. <br> De mensen met een tattoage in hun gezicht en de personen met een bril (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul en Jim (gebruiken geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee resterende personen Simon. De jongere persoon kan dan geen hartaandoening (oorbel) hebben. Dus valt Anna af en bijft Sam over.",
                    'translate.the_blackout_standard.message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.the_blackout_standard.message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik zal kijken of ik nog meer informatie kan vinden.",
                    'translate.the_blackout_standard.message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.the_blackout_standard.message3.title': "Dank! Dat lijkt te kloppen met aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",
                    'translate.the_blackout_standard.guest_message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.the_blackout_standard.guest_message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik zal kijken of ik nog meer informatie kan vinden.",
                    'translate.the_blackout_standard.guest_message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.the_blackout_standard.guest_message3.title': "Dank! Dat lijkt te kloppen met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",

                    'translate.the_blackout_challenging.timer.title': "2. Deceased persons - A Toxic Case",
                    'translate.the_blackout_challenging.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_blackout_challenging.player.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Het lijkt erop dat Charlie Yang en Joe Lopez niet vergiftigd zijn.',
                    'translate.the_blackout_challenging.player.timer.message2': 'Zie net dat op de achterkant van de foto van Simon Carlson staat dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
                    'translate.the_blackout_challenging.guest.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Het lijkt erop dat Charlie Yang en Joe Lopez niet vergiftigd zijn.',
                    'translate.the_blackout_challenging.guest.timer.message2': 'Zie net dat op de achterkant van de foto van Simon Carlson staat dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
                    'translate.the_blackout_challenging.player.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Focus je eerst op het leeftijdsverschil en wie er een hartaandoening hebben.",
                    'translate.the_blackout_challenging.player.hint.hint1': "Charlie Yang en Joe Lopez zijn niet vergiftigd.",
                    'translate.the_blackout_challenging.player.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson.",
                    'translate.the_blackout_challenging.player.hint.solution': "Simon en Sam. <br> De mensen die blond zijn, een bril hebben (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul (gebruikt geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee personen Joe of Simon. Als het Joe zou zijn, moet de jongere persoon een hartaandoening hebben. Dit geldt alleen voor Charlie, maar die heeft een tattoo (en minimaal een van de 2 heeft geen tattoo). Dus kan de oudere persoon niet Joe zijn, maar moet het Simon zijn. De jongere persoon kan dan geen hartaandoening hebben en kan geen familie zijn (dus niet Jim). Dus bijft Sam over.",
                    'translate.the_blackout_challenging.guest.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Focus je eerst op het leeftijdsverschil en wie er een hartaandoening hebben.",
                    'translate.the_blackout_challenging.guest.hint.hint1': "Charlie Yang en Joe Lopez zijn niet vergiftigd.",
                    'translate.the_blackout_challenging.guest.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson.",
                    'translate.the_blackout_challenging.guest.hint.solution': "Simon en Sam. <br> De mensen die blond zijn, een bril hebben (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul (gebruikt geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee personen Joe of Simon. Als het Joe zou zijn, moet de jongere persoon een hartaandoening hebben. Dit geldt alleen voor Charlie, maar die heeft een tattoo (en minimaal een van de 2 heeft geen tattoo). Dus kan de oudere persoon niet Joe zijn, maar moet het Simon zijn. De jongere persoon kan dan geen hartaandoening hebben en kan geen familie zijn (dus niet Jim). Dus bijft Sam over.",
                    'translate.the_blackout_challenging.message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.the_blackout_challenging.message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik onderzoek ondertussen zijn werkkamer.",
                    'translate.the_blackout_challenging.message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.the_blackout_challenging.message3.title': "Dat klopt met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop?",
                    'translate.the_blackout_challenging.guest_message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
                    'translate.the_blackout_challenging.guest_message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik onderzoek ondertussen zijn werkkamer.",
                    'translate.the_blackout_challenging.guest_message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
                    'translate.the_blackout_challenging.guest_message3.title': "Dank! Dat lijkt te kloppen met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",

                    'translate.the_traces_standard.player.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.the_traces_standard.player.hint.hint1': "Each player is missing 3 items.",
                    'translate.the_traces_standard.player.hint.hint2': "One of the items you are missing is in zone 43. You are not missing any items in zone 11.",
                    'translate.the_traces_standard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_traces_standard.guest.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.the_traces_standard.guest.hint.hint1': "Each player is missing 3 items.",
                    'translate.the_traces_standard.guest.hint.hint2': "One of the items you are missing is in zone 57. You are not missing any items in zone 77.",
                    'translate.the_traces_standard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_traces_standard.message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.the_traces_standard.message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.the_traces_standard.message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.the_traces_standard.message3.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.the_traces_standard.guest_message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.the_traces_standard.guest_message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.the_traces_standard.guest_message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.the_traces_standard.guest_message3.title': "OK, I looked these items up in the database. They have been used in a shed somewhere...",


                    'translate.the_traces_challenging.player.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.the_traces_challenging.player.hint.hint1': "Each player is missing 3 items.",
                    'translate.the_traces_challenging.player.hint.hint2': "One of the items you are missing is in zone 43. You are not missing any items in zone 11.",
                    'translate.the_traces_challenging.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_traces_challenging.guest.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
                    'translate.the_traces_challenging.guest.hint.hint1': "Each player is missing 3 items.",
                    'translate.the_traces_challenging.guest.hint.hint2': "One of the items you are missing is in zone 57. You are not missing any items in zone 77.",
                    'translate.the_traces_challenging.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_traces_challenging.message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.the_traces_challenging.message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.the_traces_challenging.message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.the_traces_challenging.message3.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.the_traces_challenging.guest_message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
                    'translate.the_traces_challenging.guest_message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
                    'translate.the_traces_challenging.guest_message2.error': "I cannot find anything based on these results. Please check again",
                    'translate.the_traces_challenging.guest_message3.title': "OK, I looked these items up in the database. They have been used in a shed somewhere...",

                    'translate.the_key_standard.timer.title': "4. Drone - A Toxic Case",
                    'translate.the_key_standard.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_key_standard.player.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
                    'translate.the_key_standard.player.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
                    'translate.the_key_standard.guest.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
                    'translate.the_key_standard.guest.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
                    'translate.the_key_standard.player.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen.",
                    'translate.the_key_standard.player.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.the_key_standard.player.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.the_key_standard.player.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jij hebt dit gebouw op jouw kaart en jouw medespeler heeft het lange gebouw in het midden. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jou zichtbaar is (B). De vierde zone zie je een groot gebouw dat ook op jouw kaart zichtbaar is (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.the_key_standard.guest.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen.",
                    'translate.the_key_standard.guest.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.the_key_standard.guest.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.the_key_standard.guest.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jouw medespeler heeft dit gebouw op de kaart en jij hebt het lange gebouw in het midden op jouw kaart. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jouw medespeler goed te zien is (B). De vierde zone zie je twee gebouwen onder elkaar die op jouw kaart staan (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.the_key_standard.message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft 2 verschillende schetsen gemaakt van het gebied. Ik stuur jou een schets van de kantoren en {{partnerName}} een schets van de laboratoria in dit gebied. Kijk naar zowel de gebouwen als de wegen.",
                    'translate.the_key_standard.message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
                    'translate.the_key_standard.message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Gebruik de TAG ANTWOORD link uit het hierboven nog een keer en laat het me weten.",
                    'translate.the_key_standard.message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",
                    'translate.the_key_standard.guest_message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft 2 verschillende schetsen gemaakt van het gebied. Ik stuur jou een schets van de laboratoria en {{partnerName}} een schets van de kantoren in dit gebied. Kijk naar zowel de gebouwen als de wegen",
                    'translate.the_key_standard.guest_message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
                    'translate.the_key_standard.guest_message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Gebruik de TAG ANTWOORD link uit het hierboven nog een keer en laat het me weten.",
                    'translate.the_key_standard.guest_message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",

                    'translate.the_key_challenging.timer.title': "4. Drone - A Toxic Case",
                    'translate.the_key_challenging.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_key_challenging.player.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
                    'translate.the_key_challenging.player.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
                    'translate.the_key_challenging.guest.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
                    'translate.the_key_challenging.guest.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
                    'translate.the_key_challenging.player.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen. Elke speler heeft andere gebouwen op de kaart staan.",
                    'translate.the_key_challenging.player.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.the_key_challenging.player.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.the_key_challenging.player.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jij hebt dit gebouw op jouw kaart en jouw medespeler heeft het lange gebouw in het midden. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jou zichtbaar is (B). De vierde zone zie je een groot gebouw dat ook op jouw kaart zichtbaar is (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.the_key_challenging.guest.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen. Elke speler heeft andere gebouwen op de kaart staan.",
                    'translate.the_key_challenging.guest.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
                    'translate.the_key_challenging.guest.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
                    'translate.the_key_challenging.guest.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jouw medespeler heeft dit gebouw op de kaart en jij hebt het lange gebouw in het midden op jouw kaart. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jouw medespeler goed te zien is (B). De vierde zone zie je twee gebouwen onder elkaar die op jouw kaart staan (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
                    'translate.the_key_challenging.message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft schetsen van het gebied gemaakt, ik stuur ze door.",
                    'translate.the_key_challenging.message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, dan kan ik een manier vinden om erlangs te komen.",
                    'translate.the_key_challenging.message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Controleer het nog een keer en laat het me weten.",
                    'translate.the_key_challenging.message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",
                    'translate.the_key_challenging.guest_message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft schetsen van het gebied gemaakt, ik stuur ze door.",
                    'translate.the_key_challenging.guest_message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Controleer het nog een keer en laat het me weten.",
                    'translate.the_key_challenging.guest_message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
                    'translate.the_key_challenging.guest_message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",

                    'translate.whodunnit_standard.timer.title': "7. Enclose - A Toxic Case",
                    'translate.whodunnit_standard.timer.subtitle': "De volgende opdracht start over:",
                    'translate.whodunnit_standard.player.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start.',
                    'translate.whodunnit_standard.player.timer.message2': 'Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Hebben jullie haar op de camera gezien in kamer 201?',
                    'translate.whodunnit_standard.guest.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start.',
                    'translate.whodunnit_standard.guest.timer.message2': 'Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Hebben jullie haar op de camera gezien in kamer 201?',
                    'translate.whodunnit_standard.player.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Jennifer en Justin (de onderzoeker) zitten. Wanneer horen ze iets en wanneer niet?",
                    'translate.whodunnit_standard.player.hint.hint1': "Justin, de onderzoeker, zit in het toilet dat vast zit aan ruimte 281. Hij hoort alleen iets in de kamer naast hem nadat Linda Steve is gepasseerd. In totaal moeten we 8 kamers vinden, inclusief het startpunt 270.",
                    'translate.whodunnit_standard.player.hint.hint2': "Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Ze is niet op camera gezien in 201, dus ze moet door ruimte 217 naar de gang zijn gegaan.",
                    'translate.whodunnit_standard.player.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.whodunnit_standard.guest.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Jennifer en Justin (de onderzoeker) zitten. Wanneer horen ze iets en wanneer niet?",
                    'translate.whodunnit_standard.guest.hint.hint1': "Justin, de onderzoeker, zit in het toilet dat vast zit aan ruimte 281. Hij hoort alleen iets in de kamer naast hem nadat Linda Steve is gepasseerd. In totaal moeten we 8 kamers vinden, inclusief het startpunt 270.",
                    'translate.whodunnit_standard.guest.hint.hint2': "Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Ze is niet op camera gezien in 201, dus ze moet door ruimte 217 naar de gang zijn gegaan.",
                    'translate.whodunnit_standard.guest.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.whodunnit_standard.message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
                    'translate.whodunnit_standard.message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Ze start in kamer 270. Kijk of je de kamers op camera kan herkennen en luister goed wat Justin en Jennifer zeggen.",
                    'translate.whodunnit_standard.message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
                    'translate.whodunnit_standard.message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",
                    'translate.whodunnit_standard.guest_message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
                    'translate.whodunnit_standard.guest_message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Ze start in kamer 270. Kijk of je de kamers op camera kan herkennen en luister goed wat Justin en Jennifer zeggen.",
                    'translate.whodunnit_standard.guest_message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
                    'translate.whodunnit_standard.guest_message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",

                    'translate.whodunnit_challenging.timer.title': "7. Enclose - A Toxic Case",
                    'translate.whodunnit_challenging.timer.subtitle': "De volgende opdracht start over:",
                    'translate.whodunnit_challenging.player.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start. Probeer uit zoeken van welke kamers we camera beelden hebben door te kijken naar de positie van de deuren.',
                    'translate.whodunnit_challenging.player.timer.message2': 'We zien Linda nooit op camera in kamer 201 verschijnen. Jennifer zit volgens mij in de opslag rechtsboven. Ze zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar.',
                    'translate.whodunnit_challenging.guest.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start. Probeer uit zoeken van welke kamers we camera beelden hebben door te kijken naar de positie van de deuren.',
                    'translate.whodunnit_challenging.guest.timer.message2': 'We zien Linda nooit op camera in kamer 201 verschijnen. Jennifer zit volgens mij in de opslag rechtsboven. Ze zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar.',
                    'translate.whodunnit_challenging.player.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Justin en Jennifer zitten. Wanneer horen ze iets en wanneer niet? En horen ze iets in de kamers die aan hen grenzen? Wat zegt dit over waar ze zitten?",
                    'translate.whodunnit_challenging.player.hint.hint1': "Justin, de onderzoeker zit in het toilet naast ruimte 281. Hij hoort alleen iets in de kamer naast hem als Linda Steve is gepasseerd. Jennifer heeft de hele tijd niets gehoord, ook niet in de ruimtes naast haar. Op welke plek kan zij zitten?",
                    'translate.whodunnit_challenging.player.hint.hint2': "Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Dit betekent dat Linda door ruimte 217 is gegaan naar de gang met de bewegingsdetector.",
                    'translate.whodunnit_challenging.player.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.whodunnit_challenging.guest.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Justin en Jennifer zitten. Wanneer horen ze iets en wanneer niet? En horen ze iets in de kamers die aan hen grenzen? Wat zegt dit over waar ze zitten?",
                    'translate.whodunnit_challenging.guest.hint.hint1': "Justin, de onderzoeker zit in het toilet naast ruimte 281. Hij hoort alleen iets in de kamer naast hem als Linda Steve is gepasseerd. Jennifer heeft de hele tijd niets gehoord, ook niet in de ruimtes naast haar. Op welke plek kan zij zitten?",
                    'translate.whodunnit_challenging.guest.hint.hint2': "Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Dit betekent dat Linda door ruimte 217 is gegaan naar de gang met de bewegingsdetector.",
                    'translate.whodunnit_challenging.guest.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
                    'translate.whodunnit_challenging.message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker, en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
                    'translate.whodunnit_challenging.message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Probeer eerst te kijken van welke kamers we camera toegang hebben.",
                    'translate.whodunnit_challenging.message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
                    'translate.whodunnit_challenging.message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",
                    'translate.whodunnit_challenging.guest_message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
                    'translate.whodunnit_challenging.guest_message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Probeer eerst te kijken van welke kamers we camera toegang hebben.",
                    'translate.whodunnit_challenging.guest_message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
                    'translate.whodunnit_challenging.guest_message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",


                    'translate.the_missing_items_standard.player.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.the_missing_items_standard.player.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.the_missing_items_standard.player.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.the_missing_items_standard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_missing_items_standard.guest.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.the_missing_items_standard.guest.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.the_missing_items_standard.guest.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.the_missing_items_standard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_missing_items_standard.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.the_missing_items_standard.message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.the_missing_items_standard.message2.error': "Wait. Were these the right doors?",
                    'translate.the_missing_items_standard.message3.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.the_missing_items_standard.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.the_missing_items_standard.guest_message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.the_missing_items_standard.guest_message2.error': "Wait. Were these the right doors?",
                    'translate.the_missing_items_standard.guest_message3.title': "OK, she must have been in one of those four rooms. I'll try to find her",


                    'translate.the_missing_items_challenging.player.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.the_missing_items_challenging.player.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.the_missing_items_challenging.player.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.the_missing_items_challenging.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_missing_items_challenging.guest.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
                    'translate.the_missing_items_challenging.guest.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
                    'translate.the_missing_items_challenging.guest.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
                    'translate.the_missing_items_challenging.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_missing_items_challenging.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.the_missing_items_challenging.message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.the_missing_items_challenging.message2.error': "Wait. Were these the right doors?",
                    'translate.the_missing_items_challenging.message3.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.the_missing_items_challenging.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
                    'translate.the_missing_items_challenging.guest_message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
                    'translate.the_missing_items_challenging.guest_message2.error': "Wait. Were these the right doors?",
                    'translate.the_missing_items_challenging.guest_message3.title': "OK, she must have been in one of those four rooms. I'll try to find her",

                    'translate.the_knight_standard.timer.title': "1. Open the Door - A Toxic Case",
                    'translate.the_knight_standard.timer.subtitle': "The volgende opdracht start over:",
                    'translate.the_knight_standard.player.timer.message1': 'Misschien heb je dit al gezien, maar op de beelden zie ik hem elke dag dezelfde vier dingen doen. Is er een verband tussen de volgorde van de dingen die hij doet en de code?',
                    'translate.the_knight_standard.player.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
                    'translate.the_knight_standard.player.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
                    'translate.the_knight_standard.guest.timer.message1': 'Misschien heb je dit al gezien, maar op de beelden zie ik hem elke dag dezelfde vier dingen doen. Is er een verband tussen de volgorde van de dingen die hij doet en de code?',
                    'translate.the_knight_standard.guest.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
                    'translate.the_knight_standard.guest.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',

                    'translate.the_knight_standard.player.hint.explanation': "Je moet een 4 cijferige code doorsturen. Hiervoor moet je een verband ontdekken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Elk getal van die dag is verbonden met een activiteit die hij doet. Je doel is te ontdekken hoe dit werkt en wat de code voor vandaag zou zijn.",
                    'translate.the_knight_standard.player.hint.hint1': "Hij gebruikt alle dagen de cijfers 1,4 en 9. Alleen het andere cijfer wisselt. Het cijfer 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Op de bewakingsbeelden is het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde.",
                    'translate.the_knight_standard.player.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag. Bijvoorbeeld als het 8 uur op de klok is, gebruikt hij een 8 in de code.",
                    'translate.the_knight_standard.player.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds de tijd die dan op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.the_knight_standard.guest.hint.explanation': "Je moet een 4 cijferige code doorsturen. Hiervoor moet je een verband ontdekken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Elk getal van die dag is verbonden met een activiteit die hij doet. Je doel is te ontdekken hoe dit werkt en wat de code voor vandaag zou zijn.",
                    'translate.the_knight_standard.guest.hint.hint1': "Hij gebruikt alle dagen de cijfers 1,4 en 9. Alleen het andere cijfer wisselt. Het cijfer 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Op de bewakingsbeelden is het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde.",
                    'translate.the_knight_standard.guest.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag. Bijvoorbeeld als het 8 uur op de klok is, gebruikt hij een 8 in de code.",
                    'translate.the_knight_standard.guest.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds de tijd die dan op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.the_knight_standard.message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.the_knight_standard.message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
                    'translate.the_knight_standard.message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
                    'translate.the_knight_standard.message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",
                    'translate.the_knight_standard.guest_message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.the_knight_standard.guest_message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
                    'translate.the_knight_standard.guest_message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
                    'translate.the_knight_standard.guest_message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",

                    'translate.the_knight_challenging.timer.title': "1. Open the Door - A Toxic Case",
                    'translate.the_knight_challenging.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_knight_challenging.player.timer.message1': 'John wist waarschijnlijk al dat ik zijn bewakingsbeelden zou kunnen bekijken, dus er moet een verband zijn tussen die beelden en de codes die hij gebruikt.',
                    'translate.the_knight_challenging.player.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
                    'translate.the_knight_challenging.player.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
                    'translate.the_knight_challenging.guest.timer.message1': 'John wist waarschijnlijk al dat ik zijn bewakingsbeelden zou kunnen bekijken, dus er moet een verband zijn tussen die beelden en de codes die hij gebruikt.',
                    'translate.the_knight_challenging.guest.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
                    'translate.the_knight_challenging.guest.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
                    'translate.the_knight_challenging.player.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Kun je een verband vinden met de vier cijferige code van die dag?",
                    'translate.the_knight_challenging.player.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Wat doet John het eerst op vrijdag, het laatst op zaterdag en als tweede op zondag?",
                    'translate.the_knight_challenging.player.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
                    'translate.the_knight_challenging.player.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds het tijdstip dat die dag op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.the_knight_challenging.guest.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Kun je een verband vinden met de vier cijferige code van die dag?",
                    'translate.the_knight_challenging.guest.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Wat doet John het eerst op vrijdag, het laatst op zaterdag en als tweede op zondag?",
                    'translate.the_knight_challenging.guest.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
                    'translate.the_knight_challenging.guest.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds het tijdstip dat die dag op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
                    'translate.the_knight_challenging.message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.the_knight_challenging.message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
                    'translate.the_knight_challenging.message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
                    'translate.the_knight_challenging.message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",
                    'translate.the_knight_challenging.guest_message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
                    'translate.the_knight_challenging.guest_message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
                    'translate.the_knight_challenging.guest_message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
                    'translate.the_knight_challenging.guest_message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",

                    'translate.the_witnesses_standard.timer.title': "3. Password - A Toxic Case",
                    'translate.the_witnesses_standard.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_witnesses_standard.player.timer.message1': 'Volgens mij heeft hij die markers expres zo laten liggen. Maar wat is de link tussen die markers en de symbolen?',
                    'translate.the_witnesses_standard.player.timer.message2': 'Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.',
                    'translate.the_witnesses_standard.player.timer.message3': 'Volgens mij wijzen die markers naar een horloge, een kom, een vaas, een boek en een fles. Voor boek is de 4e letter een k, dus zou het woord moeten beginnen met een "k"',
                    'translate.the_witnesses_standard.guest.timer.message1': 'Volgens mij heeft hij die markers expres zo laten liggen. Maar wat is de link tussen die markers en de symbolen?',
                    'translate.the_witnesses_standard.guest.timer.message2': 'Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.',
                    'translate.the_witnesses_standard.guest.timer.message3': 'Volgens mij wijzen die markers naar een horloge, een kom, een vaas, een boek en een fles. Voor boek is de 4e letter een k, dus zou het woord moeten beginnen met een "k"',
                    'translate.the_witnesses_standard.player.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
                    'translate.the_witnesses_standard.player.hint.hint1': "Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.",
                    'translate.the_witnesses_standard.player.hint.hint2': "De 5 markers wijzen naar een horloge, een kom, een vaas, een boek en een fles. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
                    'translate.the_witnesses_standard.player.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.the_witnesses_standard.guest.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
                    'translate.the_witnesses_standard.guest.hint.hint1': "Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.",
                    'translate.the_witnesses_standard.guest.hint.hint2': "De 5 markers wijzen naar een horloge, een kom, een vaas, een boek en een fles. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
                    'translate.the_witnesses_standard.guest.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.the_witnesses_standard.message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
                    'translate.the_witnesses_standard.message2.title': "Volgens mij hebben we een wachtwoord van 5 letters nodig. Ik denk dat we 5 spullen van zijn lijst moeten vinden van elk ding een letter moeten gebruiken om een woord te vormen. Maar wat hoort bij welk symbool?",
                    'translate.the_witnesses_standard.message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.the_witnesses_standard.message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",
                    'translate.the_witnesses_standard.guest_message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
                    'translate.the_witnesses_standard.guest_message2.title': "Volgens mij hebben we een wachtwoord van 5 letters nodig. Ik denk dat we 5 spullen van zijn lijst moeten vinden van elk ding een letter moeten gebruiken om een woord te vormen. Maar wat hoort bij welk symbool?",
                    'translate.the_witnesses_standard.guest_message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.the_witnesses_standard.guest_message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",

                    'translate.the_witnesses_challenging.timer.title': "3. Password - A Toxic Case",
                    'translate.the_witnesses_challenging.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_witnesses_challenging.player.timer.message1': 'Volgens mij heb ik iets gevonden. Hij heeft 5 markers laten liggen, allemaal in een hele specifieke positie.',
                    'translate.the_witnesses_challenging.player.timer.message2': 'Ik heb een letter van het woord gevonden. Een van de markers wijst naar het boek. Het boek ligt op positie X in de kamer. De vierde letter van boek is "k", dus het woord begint met een "k".',
                    'translate.the_witnesses_challenging.guest.timer.message1': 'Volgens mij heb ik iets gevonden. Hij heeft 5 markers laten liggen, allemaal in een hele specifieke positie.',
                    'translate.the_witnesses_challenging.guest.timer.message2': 'Ik heb een letter van het woord gevonden. Een van de markers wijst naar het boek. Het boek ligt op positie X in de kamer. De vierde letter van boek is "k", dus het woord begint met een "k".',
                    'translate.the_witnesses_challenging.player.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
                    'translate.the_witnesses_challenging.player.hint.hint1': "Zoek de 5 markeerstiften. Waar liggen ze en waar wijzen ze naar?",
                    'translate.the_witnesses_challenging.player.hint.hint2': "Er ligt een markeerstift op plek X die wijst naar een boek. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
                    'translate.the_witnesses_challenging.player.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.the_witnesses_challenging.guest.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
                    'translate.the_witnesses_challenging.guest.hint.hint1': "Zoek de 5 markeerstiften. Waar liggen ze en waar wijzen ze naar?",
                    'translate.the_witnesses_challenging.guest.hint.hint2': "Er ligt een markeerstift op plek X die wijst naar een boek. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
                    'translate.the_witnesses_challenging.guest.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
                    'translate.the_witnesses_challenging.message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
                    'translate.the_witnesses_challenging.message2.title': "Ik stuur de notities naar jullie door. Volgens mij zoeken we een wachtwoord van 5 letters.",
                    'translate.the_witnesses_challenging.message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.the_witnesses_challenging.message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",
                    'translate.the_witnesses_challenging.guest_message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen. Eeen plant, post-it, lepel, fles, kom, laptop, vaas en een multomap.",
                    'translate.the_witnesses_challenging.guest_message2.title': "Ik stuur de notities naar jullie door. Volgens mij zoeken we een wachtwoord van 5 letters.",
                    'translate.the_witnesses_challenging.guest_message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
                    'translate.the_witnesses_challenging.guest_message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",

                    'translate.the_interrogation_standard.timer.title': "5. Poison - A Toxic Case",
                    'translate.the_interrogation_standard.timer.subtitle': "The volgende opdracht start over:",
                    'translate.the_interrogation_standard.player.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
                    'translate.the_interrogation_standard.player.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
                    'translate.the_interrogation_standard.guest.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
                    'translate.the_interrogation_standard.guest.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
                    'translate.the_interrogation_standard.player.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
                    'translate.the_interrogation_standard.player.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
                    'translate.the_interrogation_standard.player.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
                    'translate.the_interrogation_standard.player.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
                    'translate.the_interrogation_standard.guest.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
                    'translate.the_interrogation_standard.guest.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
                    'translate.the_interrogation_standard.guest.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
                    'translate.the_interrogation_standard.guest.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
                    'translate.the_interrogation_standard.message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
                    'translate.the_interrogation_standard.message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
                    'translate.the_interrogation_standard.message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
                    'translate.the_interrogation_standard.message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",
                    'translate.the_interrogation_standard.guest_message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
                    'translate.the_interrogation_standard.guest_message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
                    'translate.the_interrogation_standard.guest_message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
                    'translate.the_interrogation_standard.guest_message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",

                    'translate.the_interrogation_challenging.timer.title': "5. Poison - A Toxic Case",
                    'translate.the_interrogation_challenging.timer.subtitle': "The volgende opdracht start over:",
                    'translate.the_interrogation_challenging.player.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
                    'translate.the_interrogation_challenging.player.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
                    'translate.the_interrogation_challenging.guest.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
                    'translate.the_interrogation_challenging.guest.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
                    'translate.the_interrogation_challenging.player.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
                    'translate.the_interrogation_challenging.player.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
                    'translate.the_interrogation_challenging.player.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
                    'translate.the_interrogation_challenging.player.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
                    'translate.the_interrogation_challenging.guest.hint.explanation': "Het doel is om het nummer van het juiste vergif door te sturen. Het vergif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Alle aanwijzingen zijn in de video te zien of daaruit af te leiden.",
                    'translate.the_interrogation_challenging.guest.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
                    'translate.the_interrogation_challenging.guest.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
                    'translate.the_interrogation_challenging.guest.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
                    'translate.the_interrogation_challenging.message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
                    'translate.the_interrogation_challenging.message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
                    'translate.the_interrogation_challenging.message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
                    'translate.the_interrogation_challenging.message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",
                    'translate.the_interrogation_challenging.guest_message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
                    'translate.the_interrogation_challenging.guest_message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
                    'translate.the_interrogation_challenging.guest_message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
                    'translate.the_interrogation_challenging.guest_message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",

                    'translate.the_security_tapes_standard.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.the_security_tapes_standard.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.the_security_tapes_standard.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.the_security_tapes_standard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_security_tapes_standard.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.the_security_tapes_standard.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.the_security_tapes_standard.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.the_security_tapes_standard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_security_tapes_standard.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
                    'translate.the_security_tapes_standard.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out which detonator and which colour wire she is planning to use.",
                    'translate.the_security_tapes_standard.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again?",
                    'translate.the_security_tapes_standard.message3.title': "OK, this makes sense. Now I can look up how to diffuse them. Let's go!",


                    'translate.the_security_tapes_challenging.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.the_security_tapes_challenging.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.the_security_tapes_challenging.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.the_security_tapes_challenging.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_security_tapes_challenging.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
                    'translate.the_security_tapes_challenging.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
                    'translate.the_security_tapes_challenging.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
                    'translate.the_security_tapes_challenging.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
                    'translate.the_security_tapes_challenging.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
                    'translate.the_security_tapes_challenging.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out which detonator and which colour wire she is planning to use.",
                    'translate.the_security_tapes_challenging.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again?",
                    'translate.the_security_tapes_challenging.message3.title': "OK, this makes sense. Now I can look up how to diffuse them. Let's go!",

                    'translate.the_card_game_standard.timer.title': "6. Who did it - A Toxic Case",
                    'translate.the_card_game_standard.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_card_game_standard.player.timer.message1': '{{playerName}}, kun jij kijken naar het aantal kopjes aan het begin en eind van elke video? Ik zal ook proberen om wat aantekeningen te maken.',
                    'translate.the_card_game_standard.player.timer.message2': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. Nummer 31 eindigt met 1 kopje, maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.the_card_game_standard.player.timer.message3': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.the_card_game_standard.guest.timer.message1': '{{playerName}}, kun jij kijken of er rechts in beeld een deurmat aan het begin en eind van elke video ligt? Ik zal ook proberen om wat aantekeningen te maken.',
                    'translate.the_card_game_standard.guest.timer.message2': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. Nummer 31 eindigt met 1 kopje, maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.the_card_game_standard.guest.timer.message3': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.the_card_game_standard.player.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de deurmat die rechts in beeld op de grond ligt en laat je medespeler letten op de koffiekopjes. Wat is de situatie aan het begin en einde van elke video?",
                    'translate.the_card_game_standard.player.hint.hint1': "Nummer 31 eindigt met 1 kopje maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
                    'translate.the_card_game_standard.player.hint.hint2': "De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
                    'translate.the_card_game_standard.player.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
                    'translate.the_card_game_standard.guest.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de koffiekopjes en laat je medespeler letten op de deurmat die rechts op de grond ligt. Wat is de situatie aan het begin en einde van elke video?",
                    'translate.the_card_game_standard.guest.hint.hint1': "Nummer 31 eindigt met 1 kopje maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
                    'translate.the_card_game_standard.guest.hint.hint2': "De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
                    'translate.the_card_game_standard.guest.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
                    'translate.the_card_game_standard.message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes. Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
                    'translate.the_card_game_standard.message2.title': "Kijk of je iets ziet wat ons kan helpen om de beelden in de juiste volgorde te plaatsen.",
                    'translate.the_card_game_standard.message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.the_card_game_standard.message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",
                    'translate.the_card_game_standard.guest_message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes. Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
                    'translate.the_card_game_standard.guest_message2.title': "Kijk of je iets ziet wat ons kan helpen om de beelden in de juiste volgorde te plaatsen.",
                    'translate.the_card_game_standard.guest_message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.the_card_game_standard.guest_message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",

                    'translate.the_card_game_challenging.timer.title': "6. Who did it - A Toxic Case",
                    'translate.the_card_game_challenging.timer.subtitle': "De volgende opdracht start over:",
                    'translate.the_card_game_challenging.player.timer.message1': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. De eerste video begint zonder kopjes op het aanrecht en met een deurmat rechts in beeld. Aan het einde van deze video staat er 1 kopje op het aanrecht en is de deurmat weg.',
                    'translate.the_card_game_challenging.player.timer.message2': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.the_card_game_challenging.guest.timer.message1': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. De eerste video begint zonder kopjes op het aanrecht en met een deurmat rechts in beeld. Aan het einde van deze video staat er 1 kopje op het aanrecht en is de deurmat weg.',
                    'translate.the_card_game_challenging.guest.timer.message2': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
                    'translate.the_card_game_challenging.player.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de deurmat rechts in beeld en laat je medespeler letten op de koffiekopjes. Wat is de situatie aan het begin en einde van elke video?",
                    'translate.the_card_game_challenging.player.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Aan het einde van deze video staat er een kopje en is de deurmat weg.",
                    'translate.the_card_game_challenging.player.hint.hint2': "De tweede video start met 1 koffiekopje en zonder mat en eindigt met 2 kopjes en een mat. De derde video is 22. Aan het begin van deze video staan er 2 kopjes en een deurmat en aan het einde staan er drie kopjes en een mat op de grond",
                    'translate.the_card_game_challenging.player.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
                    'translate.the_card_game_challenging.guest.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de koffiekopjes en laat je medespeler letten op de deurmat rechts in beeld. Wat is de situatie aan het begin en einde van elke video?",
                    'translate.the_card_game_challenging.guest.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Aan het einde van deze video staat er 1 kopje en is de deurmat weg.",
                    'translate.the_card_game_challenging.guest.hint.hint2': "De tweede video begint met 1 koffiekopje en zonder deurmat en eindigt met 2 kopjes en een deurmat. De derde video is 22. Aan het begin van deze video staan er 2 kopjes en een deurmat en aan het einde staan er drie kopjes en een mat op de grond.",
                    'translate.the_card_game_challenging.guest.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
                    'translate.the_card_game_challenging.message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes.",
                    'translate.the_card_game_challenging.message2.title': "Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
                    'translate.the_card_game_challenging.message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.the_card_game_challenging.message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",
                    'translate.the_card_game_challenging.guest_message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes.",
                    'translate.the_card_game_challenging.guest_message2.title': "Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
                    'translate.the_card_game_challenging.guest_message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
                    'translate.the_card_game_challenging.guest_message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",
                },
                tagOptionsDialog: {
                    availableOptions: "Beschikbare opties ({{count}})",
                    submitButton: "Verzenden",
                    clearBtn: "Leeg",
                },
                taggerCarousel: {
                    altText: "afbeelding taggen",
                    confirmButton: "Bevestigen",
                    submitButtonText: "Verzenden",
                    tagAllImages: "Tag alle afbeeldingen"
                },
                resultsDialog: {
                    resultsTitle: "Resultaten",
                    resultsScoreDifficultyCap: "Jullie zijn te slim voor dit niveau... We hebben de moeilijkheidsgraad verhoogd naar uitdagend. De puzzels zullen waarschijnlijk iets langer duren, maar je zult meer punten verdienen. Wil je weer terug naar Standaard? Dan kan speler 1 tijdens de opdracht de moeilijkheid aanpassen in het menu.",
                    hintsUsed: "Totaal gebruikte hints",
                    incorrectScore: "Totaal incorrecte score",
                    solutionsTried: "Totaal geprobeerde oplossingen",
                    timeSpent: "Besteedde tijd",
                    totalPlayerHintsUsed: "Totaal door speler gebruikte hints",
                    totalGuestHintsUsed: "Totaal door gast gebruikte hints",
                    totalPlayerSolutionsTried: "Totaal aantal onjuiste pogingen",
                    grandTotalResults: "Resultaat alle puzzels",
                    totalScore: "Score",
                    congratulationsText: "Gefeliciteerd! Jullie hebben de zaak opgelost!",
                    waitMessage: "Wacht tot de andere speler op resultaten drukt en probeer opnieuw!",
                    goToMenu: "Ga naar Menu",
                    continueButton: "Doorgaan",
                    curiousFriends: "Wil je de score van jullie vrienden weten?",
                    shareWithFriends: "Nodig ze uit en vergelijk jullie scores!",
                    shareMessage: "{{playerName}} en {{partnerName}} dagen jullie uit voor een TV escaperoom! Ze hebben een score van {{score}} behaald voor {{puzzleName}} in {{gameName}}. Accepteer je deze uitdaging? Ga naar {{link}}",
                    shareGrandTotalMessage: "{{playerName}} en {{partnerName}} dagen jullie uit voor een TV escaperoom! Ze hebben een score van {{score}} behaald in {{gameName}}. Accepteer je deze uitdaging? Ga naar {{link}}"
                },
                continuePuzzleVideo: {
                    'Intro 1': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - Intro 2 - en druk tegelijk op Doorgaan.',
                    'Intro 2': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
                    'Deceased person': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 3. Password - en druk tegelijk op Doorgaan.',
                    'Differences': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
                    'Drone': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 5. Poison - en druk tegelijk op Doorgaan.',
                    'Enclose': 'Gefeliciteerd! Jullie hebben het samen opgelost. Klik op Doorgaan om je totaalscore te bekijken en jullie vrienden uit te dagen!',
                    'Escape': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
                    'Open the door': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 2. Deceased persons - en druk tegelijk op Doorgaan.',
                    'Password': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 4. Drone - en druk tegelijk op Doorgaan.',
                    'Poison': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 6. Who did it? - en druk tegelijk op Doorgaan.',
                    'Tools and wires': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
                    'Who did it': 'Zijn jullie klaar voor de volgende opdracht? Start de volgende video - 7. Enclose - en druk tegelijk op Doorgaan.',
                },
                puzzleCard: {
                    difficultyPlayed: "Gespeelde moeilijkheidsgraad:",
                    hintsUsedByPlayer: "Aantal door speler gebruikte hints:",
                    hintsUsedByGuest: "Aantal door gast gebruikte hints:",
                    solutionsTriedByPlayer: "Aantal door speler geprobeerde oplossingen:",
                    solutionsTriedByGuest: "Aantal door gast geprobeerde oplossingen:",
                    expires: "Verloopt",
                    seeStats: "Bekijk statistieken"
                },
                problemModal: {
                    sorry: "Het spijt ons van de bug! Maar geen zorgen, we zijn hier om het weer op het juiste spoor te brengen!",
                    clickContinue: "Door op doorgaan te klikken, start je de puzzel opnieuw op.",
                    areYouSure: "Weet je het zeker?",
                    reset: "Reset",
                    guestNeedRejoin: "De gast moet opnieuw worden uitgenodigd. Deel de link met de gastspeler.",
                    inviteContinue: "Uitgenodigd, ga door!",
                    doneOrSupport: "Dat is het! Refresh deze pagina en het spel gaat door. Neem contact op met de klantenservice als dit probleem aanhoudt.",
                    reloadPage: "Refresh pagina",
                    problem: "Probleem?",
                },
                instructionsModal: {
                    instruction1: "Hoe werkt het spel?<br>" +
                        "<br>1. A Toxic Case is een spel voor 2 spelers en gaat over een vermissingszaak. Om het spel te spelen heeft iedere speler zijn eigen smartphone nodig." +
                        "<br>2. Beide spelers krijgen steeds dezelfde opdracht, maar ontvangen verschillende informatie.  " +
                        "<br>3. Om de opdracht uit te voeren, kijken jullie samen naar dezelfde video. Jullie zullen de informatie die je hebt ontvangen moeten bespreken en samen moeten werken." +
                        "<br>4. De videos kun je bekijken via Vimeo of het CouchClues Youtube kanaal. We raden aan om het op je TV te bekijken of te streamen, maar je kan elk scherm gebruiken wat je wil." +
                        "<br>5. Iedere video start met een deel van het verhaal en daarna beelden die betrekking hebben op je opdracht. De beelden die je nodig hebt zullen steeds worden herhaald, zodat je een nieuwe kans hebt de zaak op te lossen.",
                    instruction2: "Hints en scores<br>" +
                        "<br>1. Zodra je een opdracht hebt gekregen start er een timer. Je kunt proberen de opdrachten zo snel mogelijk uit te voeren om een hoge score te halen, maar je kunt dit ook negeren en het rustig op je eigen tijd doen." +
                        "<br>2. Als het niet lukt om de opdracht uit te voeren, kun je in het menu rechtsboven een hint gebruiken. De uitleg helpt als je geen idee hebt hoe je moet beginnen en de andere hints zijn er voor als je niet verder komt." +
                        "<br>3. Beide spelers kunnen dezelfde hint bekijken zonder dat dit extra punten kost." +
                        "<br>4. Kom je er met de hints niet uit, dan kun je op Oplossing klikken om de oplossing en de uitleg te zien.",
                    instruction3: "Hoe start je het spel?<br>" +
                        "<br>1. De speler die het spel heeft gekocht ontvangt een link. Met de link kan Speler 1 het spel opstarten." +
                        "<br>2. Als speler 1 het spel opstart krijgt deze een nieuwe link, waarmee een gast speler kan worden uitgenodigd." +
                        "<br>3. Het spel start met twee intro opdrachten om het spel te leren kennen." +
                        "<br>3. Als je een bug of een ander probleem ervaart, kan Speler 1 op de knop Problemen klikken om de huidige opdracht opnieuw op te starten.",
                    btnText: 'Begrepen!'
                },
                navbar: {
                    inviteFriend: "Nodig een vriend uit",
                    instructions: "Instructies",
                    instructionsUrl: "https://couchclues.com/instructions/",
                    problem: "Problemen?",
                    quit: "Stoppen",
                },
                difficultyModal: {
                    areYouSure: "Weet je zeker dat je de moeilijkheidsgraad wilt veranderen naar",
                    dialogContent: "Door de moeilijkheidsgraad te veranderen, start je deze puzzel opnieuw. \n Je gastspeler moet de pagina refreshen om verder te gaan. Weet je zeker dat je wilt doorgaan?",
                    cancel: "Annuleren",
                    confirm: "Bevestigen",
                }
            }

        },
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: ["game", "tagOptionsDialog", "taggerCarousel", "resultsDialog", "puzzleCard", "problemModal", "navbar", "difficultyModal"],
        defaultNS: "game",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
